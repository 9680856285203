import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "container pt-3 pb-5" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "card p-5" }
const _hoisted_4 = { class: "card-title align-items-start flex-column" }
const _hoisted_5 = { class: "fs-4" }
const _hoisted_6 = { class: "position-relative d-inline-block" }
const _hoisted_7 = {
  class: "table-container",
  style: {"max-height":"700px","overflow-y":"auto"}
}
const _hoisted_8 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { colspan: "8" }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { colspan: "8" }
const _hoisted_13 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 0,
  class: "col-6"
}
const _hoisted_17 = { class: "card p-5" }
const _hoisted_18 = { class: "card-title align-items-start flex-column" }
const _hoisted_19 = { class: "fs-4" }
const _hoisted_20 = { class: "position-relative d-inline-block" }
const _hoisted_21 = {
  class: "table-container",
  style: {"max-height":"700px","overflow-y":"auto"}
}
const _hoisted_22 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { colspan: "8" }
const _hoisted_25 = { key: 1 }
const _hoisted_26 = { colspan: "8" }
const _hoisted_27 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 1 }

import { ref, onMounted, inject } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AllDevicesIndex',
  setup(__props) {

const isLoading = ref(true);
const api: any = inject("api");
const allDevices = ref([]);
const roadwayDevices: any = ref([]);
const intersectionDevices: any = ref([]);

const getDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      } else if (device.type === "1") {
        roadwayDevices.value.push(device);
      }
    });
    isLoading.value = false;
  });
};

onMounted(() => {
  getDevices();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass({
          'col-6': allDevices.value.length > 2,
          'col-12': intersectionDevices.value.length == 0,
        })
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h3", _hoisted_4, [
            _cache[2] || (_cache[2] = _createElementVNode("span", { class: "fw-bold fs-2x mb-3" }, "Roadway Devices", -1)),
            _createElementVNode("div", _hoisted_5, [
              _cache[1] || (_cache[1] = _createElementVNode("span", { class: "opacity-75" }, " You have ", -1)),
              _createElementVNode("span", _hoisted_6, [
                _createVNode(_component_router_link, {
                  to: "/devices",
                  class: "opacity-75-hover fw-bold d-block mb-1"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(roadwayDevices.value.length) + " devices ", 1)
                  ]),
                  _: 1
                }),
                _cache[0] || (_cache[0] = _createElementVNode("span", { class: "position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100" }, null, -1))
              ])
            ])
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
                  _createElementVNode("th", { class: "" }, "Name"),
                  _createElementVNode("th", { class: "" }, "Location"),
                  _createElementVNode("th", { class: "" }, "Status")
                ])
              ], -1)),
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_10, _toDisplayString(_ctx.$t("tips.loading")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!isLoading.value && roadwayDevices.value.length == 0)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_11, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_12, _toDisplayString(_ctx.$t("tips.nodata")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!isLoading.value && roadwayDevices.value.length > 0)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(roadwayDevices.value, (device, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        _createElementVNode("td", null, [
                          _createVNode(_component_router_link, {
                            to: {
                        name: 'deviceDetail',
                        params: { id: device.device_id },
                      }
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(device.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to"])
                        ]),
                        _createElementVNode("td", null, _toDisplayString(device.location), 1),
                        (device.status == '1')
                          ? (_openBlock(), _createElementBlock("td", _hoisted_14, _cache[3] || (_cache[3] = [
                              _createElementVNode("div", { class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" }, " Online ", -1)
                            ])))
                          : _createCommentVNode("", true),
                        (device.status == '2')
                          ? (_openBlock(), _createElementBlock("td", _hoisted_15, _cache[4] || (_cache[4] = [
                              _createElementVNode("div", { class: "btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3" }, " Offline ", -1)
                            ])))
                          : _createCommentVNode("", true)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ], 2),
      (intersectionDevices.value.length > 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("h3", _hoisted_18, [
                _cache[9] || (_cache[9] = _createElementVNode("span", { class: "fw-bold fs-2x mb-3" }, "Intersection Devices", -1)),
                _createElementVNode("div", _hoisted_19, [
                  _cache[8] || (_cache[8] = _createElementVNode("span", { class: "opacity-75" }, " You have ", -1)),
                  _createElementVNode("span", _hoisted_20, [
                    _createVNode(_component_router_link, {
                      to: "/intersection",
                      class: "opacity-75-hover fw-bold d-block mb-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(intersectionDevices.value.length) + " devices ", 1)
                      ]),
                      _: 1
                    }),
                    _cache[7] || (_cache[7] = _createElementVNode("span", { class: "position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100" }, null, -1))
                  ])
                ])
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "separator separator-dashed my-5" }, null, -1)),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("table", _hoisted_22, [
                  _cache[12] || (_cache[12] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
                      _createElementVNode("th", { class: "" }, "Name"),
                      _createElementVNode("th", { class: "" }, "Loacation"),
                      _createElementVNode("th", { class: "" }, "Status")
                    ])
                  ], -1)),
                  (isLoading.value)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_23, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", _hoisted_24, _toDisplayString(_ctx.$t("tips.loading")), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!isLoading.value && intersectionDevices.value.length == 0)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_25, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", _hoisted_26, _toDisplayString(_ctx.$t("tips.nodata")), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!isLoading.value && intersectionDevices.value.length > 0)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_27, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intersectionDevices.value, (device, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, [
                              _createVNode(_component_router_link, {
                                to: {
                        name: 'intersectionDetail',
                        params: { id: device.device_id },
                      }
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(device.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]),
                            _createElementVNode("td", null, _toDisplayString(device.location), 1),
                            (device.status == '1')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_28, _cache[10] || (_cache[10] = [
                                  _createElementVNode("div", { class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" }, " Online ", -1)
                                ])))
                              : _createCommentVNode("", true),
                            (device.status == '2')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_29, _cache[11] || (_cache[11] = [
                                  _createElementVNode("div", { class: "btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3" }, " Offline ", -1)
                                ])))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})