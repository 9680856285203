import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row pt-3 pb-5" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = { class: "card p-5" }
const _hoisted_4 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { colspan: "8" }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { colspan: "8" }
const _hoisted_9 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_10 = {
  key: 1,
  class: "col-3"
}
const _hoisted_11 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_12 = { class: "card-header border-0" }
const _hoisted_13 = { class: "card-toolbar" }
const _hoisted_14 = { class: "card-body pt-2" }
const _hoisted_15 = { class: "symbol symbol-50px me-5" }
const _hoisted_16 = { class: "flex-grow-1" }
const _hoisted_17 = { class: "text-muted d-block fw-semobold" }
const _hoisted_18 = {
  key: 2,
  class: "col-9"
}
const _hoisted_19 = {
  key: 3,
  class: "col-9"
}

import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import UserDetailView from "./UserDetail.vue";
import UserCreateView from "./UserCreate.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersIndex',
  setup(__props) {

const router = useRouter();
const isLoading = ref(true);
const api = inject("api");
const items = ref([]);
const isShowDetail = ref(false);
const isShowCreate = ref(false);
const detailId = ref(0);

const fetchData = async () => {
  api["users.getUsers"]().then((data) => {
    console.log("data: ", data.data.data);
    items.value = data.data.data;
    isLoading.value = false;
  });
};

const showDetail = (id) => {
  detailId.value = id;
  isShowCreate.value = false;
  isShowDetail.value = true;
};

const hideDetail = () => {
  isShowDetail.value = false;
  detailId.value = 0;
};

const showCreate = () => {
  isShowCreate.value = true;
  isShowDetail.value = false;
};

const hideCreate = () => {
  isShowCreate.value = false;
};

onMounted(() => {
  if (router.currentRoute.value.params.id != undefined) {
    if (router.currentRoute.value.params.id == "create") {
      isShowCreate.value = true;
    } else {
      showDetail(router.currentRoute.value.params.id);
    }
  }
  fetchData();
});

return (_ctx: any,_cache: any) => {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isShowDetail.value && !isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("table", _hoisted_4, [
              _cache[1] || (_cache[1] = _createElementVNode("thead", null, [
                _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
                  _createElementVNode("th", { class: "" }, "ID"),
                  _createElementVNode("th", { class: "" }, "Name"),
                  _createElementVNode("th", { class: "" }, "Email"),
                  _createElementVNode("th", { class: "" }, "Created At")
                ])
              ], -1)),
              (isLoading.value)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_5, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.$t("tips.loading")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!isLoading.value && items.value.length == 0)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_7, [
                    _createElementVNode("tr", null, [
                      _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.$t("tips.nodata")), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (!isLoading.value && items.value.length > 0)
                ? (_openBlock(), _createElementBlock("tbody", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                      return (_openBlock(), _createElementBlock("tr", { key: index }, [
                        _createElementVNode("td", null, _toDisplayString(item.id), 1),
                        _createElementVNode("td", null, [
                          _createVNode(_component_router_link, {
                            to: { name: 'ManageUserDetail', params: { id: item.id } },
                            onClick: ($event: any) => (showDetail(item.id))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_UserAvatar, {
                                avatar: item.avatar_url,
                                name: item.name,
                                size: "50px"
                              }, null, 8, ["avatar", "name"]),
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["to", "onClick"])
                        ]),
                        _createElementVNode("td", null, _toDisplayString(item.email), 1),
                        _createElementVNode("td", null, _toDisplayString(item.created_at), 1)
                      ]))
                    }), 128))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (isShowDetail.value || isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Users", -1)),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_router_link, {
                  to: { name: 'ManageUserDetail', params: { id: 'create' } },
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showCreate()))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Create")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass([{ 'mb-7': items.value.length - 1 !== index }, "d-flex align-items-center"])
                }, [
                  _createElementVNode("div", _hoisted_15, [
                    _createVNode(_component_UserAvatar, {
                      avatar: item.avatar_url,
                      name: item.name,
                      size: "50px"
                    }, null, 8, ["avatar", "name"])
                  ]),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_component_router_link, {
                      to: { name: 'ManageUserDetail', params: { id: item.id } },
                      onClick: ($event: any) => (showDetail(item.id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "onClick"]),
                    _createElementVNode("span", _hoisted_17, _toDisplayString(item.email), 1)
                  ])
                ], 2))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (isShowDetail.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createVNode(UserDetailView, {
            detailId: detailId.value,
            onClose: hideDetail
          }, null, 8, ["detailId"])
        ]))
      : _createCommentVNode("", true),
    (isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
          _createVNode(UserCreateView, { onClose: hideCreate })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})