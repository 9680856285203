import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-center flex-column-fluid" }
const _hoisted_2 = { class: "d-flex flex-column flex-center text-center p-10" }
const _hoisted_3 = { class: "card card-flush w-lg-650px py-5" }
const _hoisted_4 = { class: "card-body py-15 py-lg-20" }
const _hoisted_5 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createStaticVNode("<h1 class=\"fw-bolder fs-2hx text-gray-900 mb-4\">Oops!</h1><div class=\"fw-semibold fs-6 text-gray-500 mb-7\"> You can&#39;t visit that page. </div><div class=\"mb-3\"><img src=\"/images/errors/404-error.png\" class=\"mw-100 mh-300px theme-light-show\" alt=\"\"><img src=\"/images/errors/404-error-dark.png\" class=\"mw-100 mh-300px theme-dark-show\" alt=\"\"></div>", 3)),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "btn btn-sm btn-primary"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Return Home")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}