import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, createTextVNode as _createTextVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { id: "kt_account_settings_profile_details" }
const _hoisted_3 = {
  id: "kt_account_profile_details_form",
  class: "form"
}
const _hoisted_4 = { class: "card-body border-top p-9" }
const _hoisted_5 = { class: "row mb-6" }
const _hoisted_6 = { class: "col-lg-8" }
const _hoisted_7 = {
  class: "image-input image-input-outline",
  "data-kt-image-input": "true",
  style: {"background-image":"url('assets/media/svg/avatars/blank.svg')"}
}
const _hoisted_8 = {
  class: "btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow",
  "data-kt-image-input-action": "change",
  "data-bs-toggle": "tooltip",
  title: "Change avatar"
}
const _hoisted_9 = { class: "row mb-6" }
const _hoisted_10 = { class: "col-lg-8 fv-row" }
const _hoisted_11 = { class: "row mb-6" }
const _hoisted_12 = { class: "col-lg-8" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-lg-3 fv-row" }
const _hoisted_15 = ["value"]
const _hoisted_16 = { class: "col-lg-9 fv-row" }
const _hoisted_17 = { class: "row mb-6" }
const _hoisted_18 = { class: "col-lg-8 fv-row" }
const _hoisted_19 = ["value"]
const _hoisted_20 = { class: "row mb-6" }
const _hoisted_21 = { class: "col-lg-8 fv-row" }
const _hoisted_22 = { class: "row mb-6" }
const _hoisted_23 = { class: "col-lg-8 fv-row" }
const _hoisted_24 = { class: "row mb-6" }
const _hoisted_25 = { class: "col-lg-8 fv-row" }

import AuthImage from "@/components/AuthImage.vue";
import { ref, inject } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ErrorMsg from "@/components/ErrorMsg";
import phoneData from "@/core/data/phonesData";

interface UserData {
  full_name: string;
  ccc: string;
  phone: string;
  country: string;
  lang: string;
  currency: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileSetting',
  setup(__props) {

const store = useStore();
const user = store.state.AuthModule.user;
const api = inject("api");
const isLoading = ref(true);

const collectData = ref({
  full_name: user.name,
  ccc: user.ccc,
  phone: user.phone,
  country: user.country,
  timezone: user.timezone,
  lang: user.lang,
  currency: user.currency,
}) as UserData;

var form = new FormData();

function avatarUpload(event) {
  console.log("upload");

  if (event.target.files.item(0) == null) return;

  const file = event.target.files.item(0);
  const reader = new FileReader();
  reader.readAsDataURL(file);

  form.append("avatar", file);

  api["profile.avatar"]({ data: form })
    .then(({ data }) => {
      console.log("avatar return data:", data);
      store.dispatch(Actions.SET_AVATAR, data);
    })
    .catch(({ response }) => {
      console.log(response);
      ErrorMsg.show(response);
      isLoading.value = false;
    });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[23] || (_cache[23] = _createElementVNode("div", { class: "card-header border-0 cursor-pointer" }, [
      _createElementVNode("div", { class: "card-title m-0" }, [
        _createElementVNode("h3", { class: "fw-bold m-0" }, "Profile Details")
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[10] || (_cache[10] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-semibold fs-6" }, "Avatar", -1)),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(AuthImage, {
                  url: _unref(user).avatar_url,
                  alt: _unref(user).name,
                  class: "image-input-wrapper w-125px h-125px"
                }, null, 8, ["url", "alt"]),
                _createElementVNode("label", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("i", { class: "bi bi-pencil-fill fs-7" }, null, -1)),
                  _createElementVNode("input", {
                    id: "avatarInput",
                    type: "file",
                    name: "avatar",
                    accept: ".png, .jpg, .jpeg",
                    onChange: avatarUpload
                  }, null, 32),
                  _cache[8] || (_cache[8] = _createElementVNode("input", {
                    type: "hidden",
                    name: "avatar_remove"
                  }, null, -1))
                ])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "form-text" }, "Allowed file types: png, jpg, jpeg.", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _cache[11] || (_cache[11] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-semibold fs-6" }, "Full Name", -1)),
            _createElementVNode("div", _hoisted_10, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((collectData.value.full_name) = $event)),
                type: "text",
                name: "full_name",
                class: "form-control form-control-lg form-control-solid",
                placeholder: "Full name"
              }, null, 512), [
                [_vModelText, collectData.value.full_name]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _cache[13] || (_cache[13] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-semibold fs-6" }, "Contact Phone", -1)),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("div", _hoisted_14, [
                  _withDirectives(_createElementVNode("select", {
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((collectData.value.ccc) = $event)),
                    name: "country",
                    "aria-label": "Select a Country",
                    "data-control": "select2",
                    "data-placeholder": "Select a country...",
                    class: "form-select form-select-solid form-select-lg fw-semibold mb-3 mb-lg-0"
                  }, [
                    _cache[12] || (_cache[12] = _createElementVNode("option", { value: "" }, "Code", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(phoneData), (item, index) => {
                      return (_openBlock(), _createElementBlock("option", {
                        key: index,
                        value: item.dialCode
                      }, " + " + _toDisplayString(item.dialCode) + " " + _toDisplayString(item.name), 9, _hoisted_15))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, collectData.value.ccc]
                  ])
                ]),
                _createElementVNode("div", _hoisted_16, [
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((collectData.value.phone) = $event)),
                    type: "text",
                    name: "lname",
                    class: "form-control form-control-lg form-control-solid",
                    placeholder: "Contact phone"
                  }, null, 512), [
                    [_vModelText, collectData.value.phone]
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_17, [
            _cache[15] || (_cache[15] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-semibold fs-6" }, [
              _createElementVNode("span", { class: "required" }, "Country"),
              _createElementVNode("i", {
                class: "fas fa-exclamation-circle ms-1 fs-7",
                "data-bs-toggle": "tooltip",
                title: "Country of origination"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_18, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((collectData.value.country) = $event)),
                name: "country",
                "aria-label": "Select a Country",
                "data-control": "select2",
                "data-placeholder": "Select a country...",
                class: "form-select form-select-solid form-select-lg fw-semibold"
              }, [
                _cache[14] || (_cache[14] = _createElementVNode("option", { value: "" }, "Select a Country...", -1)),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(phoneData), (item, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: item.code
                  }, _toDisplayString(item.name), 9, _hoisted_19))
                }), 128))
              ], 512), [
                [_vModelSelect, collectData.value.country]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[17] || (_cache[17] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-semibold fs-6" }, [
              _createElementVNode("span", { class: "required" }, "Time Zone"),
              _createElementVNode("i", {
                class: "fas fa-exclamation-circle ms-1 fs-7",
                "data-bs-toggle": "tooltip",
                title: "Country of origination"
              })
            ], -1)),
            _createElementVNode("div", _hoisted_21, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((collectData.value.timezone) = $event)),
                name: "timezone",
                "aria-label": "Select a Timezone",
                "data-control": "select2",
                "data-placeholder": "Select a Timezone...",
                class: "form-select form-select-solid form-select-lg fw-semibold"
              }, _cache[16] || (_cache[16] = [
                _createElementVNode("option", { value: "" }, "Select a Timezone...", -1)
              ]), 512), [
                [_vModelSelect, collectData.value.timezone]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[19] || (_cache[19] = _createElementVNode("label", { class: "col-lg-4 col-form-label required fw-semibold fs-6" }, "Language", -1)),
            _createElementVNode("div", _hoisted_23, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((collectData.value.lang) = $event)),
                name: "language",
                "aria-label": "Select a Language",
                "data-control": "select2",
                "data-placeholder": "Select a language...",
                class: "form-select form-select-solid form-select-lg"
              }, _cache[18] || (_cache[18] = [
                _createStaticVNode("<option value=\"\">Select a Language...</option><option data-kt-flag=\"flags/thailand.svg\" value=\"en\"> English - United States </option><option data-kt-flag=\"flags/thailand.svg\" value=\"th\"> ภาษาไทย - Thai </option><option data-kt-flag=\"flags/south-korea.svg\" value=\"ko\"> 한국어 - Korean </option><option data-kt-flag=\"flags/japan.svg\" value=\"ja\"> 日本語 - Japanese </option><option data-kt-flag=\"flags/china.svg\" value=\"zh-cn\"> 简体中文 - Simplified Chinese </option><option data-kt-flag=\"flags/taiwan.svg\" value=\"zh-tw\"> 繁體中文 - Traditional Chinese </option>", 7)
              ]), 512), [
                [_vModelSelect, collectData.value.lang]
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[21] || (_cache[21] = _createElementVNode("label", { class: "col-lg-4 col-form-label fw-semibold fs-6" }, "Currency", -1)),
            _createElementVNode("div", _hoisted_25, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((collectData.value.currency) = $event)),
                name: "currnecy",
                "aria-label": "Select a Currency",
                "data-control": "select2",
                "data-placeholder": "Select a currency..",
                class: "form-select form-select-solid form-select-lg"
              }, _cache[20] || (_cache[20] = [
                _createStaticVNode("<option value=\"\">Select a currency..</option><option data-kt-flag=\"flags/united-states.svg\" value=\"USD\"><b>USD</b> - USA dollar </option><option data-kt-flag=\"flags/united-kingdom.svg\" value=\"GBP\"><b>GBP</b> - British pound </option><option data-kt-flag=\"flags/australia.svg\" value=\"AUD\"><b>AUD</b> - Australian dollar </option><option data-kt-flag=\"flags/japan.svg\" value=\"JPY\"><b>JPY</b> - Japanese yen </option><option data-kt-flag=\"flags/sweden.svg\" value=\"SEK\"><b>SEK</b> - Swedish krona </option><option data-kt-flag=\"flags/canada.svg\" value=\"CAD\"><b>CAD</b> - Canadian dollar </option><option data-kt-flag=\"flags/switzerland.svg\" value=\"CHF\"><b>CHF</b> - Swiss franc </option>", 8)
              ]), 512), [
                [_vModelSelect, collectData.value.currency]
              ])
            ])
          ])
        ]),
        _cache[22] || (_cache[22] = _createElementVNode("div", { class: "card-footer d-flex justify-content-end py-6 px-9" }, [
          _createElementVNode("button", {
            type: "reset",
            class: "btn btn-light btn-active-light-primary me-2"
          }, " Discard "),
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary",
            id: "kt_account_profile_details_submit"
          }, " Save Changes ")
        ], -1))
      ])
    ])
  ]))
}
}

})