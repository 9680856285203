import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title fw-bold text-dark" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "svg-icon svg-icon-1" }

import { onMounted } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'UserDetail',
  props: {
  detailId: { type: Number, required: true },
},
  setup(__props) {

const props = __props;
onMounted(() => {
  console.log(props.detailId);
});

return (_ctx: any,_cache: any) => {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, "Users (" + _toDisplayString(__props.detailId) + ")", 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "btn btn-sm btn-icon btn-active-color-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "/images/icons/arrows/arr061.svg" })
          ])
        ])
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-body" }, null, -1))
  ]))
}
}

})