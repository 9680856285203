import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]

import { ref, watch } from "vue";
import JwtService from "@/core/services/JwtService";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthImage',
  props: {
  url: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const src = ref("");

const processImageUrl = (url: string) => {
  let img_src = "";
  if (url == "no-image") img_src = "/images/no-image.png";
  if (url == "no-avatar") img_src = "/images/avatar.png";
  else {
    img_src =
      process.env.VUE_APP_API_URL +
      url +
      "?access_token=" +
      JwtService.getToken();
  }
  return img_src;
};

src.value = processImageUrl(props.url);

watch(
  () => props.url,
  function () {
    src.value = processImageUrl(props.url);
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("img", { src: src.value }, null, 8, _hoisted_1))
}
}

})