import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-3" }
const _hoisted_4 = { class: "card" }
const _hoisted_5 = { class: "card-body pt-2" }
const _hoisted_6 = {
  class: "list-container",
  style: {"max-height":"700px","overflow-y":"auto"}
}
const _hoisted_7 = {
  class: "accordion accordion-flush",
  id: "accordionExample"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["data-bs-target", "aria-controls"]
const _hoisted_10 = ["id", "aria-labelledby"]
const _hoisted_11 = { class: "accordion-body" }
const _hoisted_12 = { class: "flex-grow-1" }
const _hoisted_13 = { class: "text-muted fw-semobold d-block" }
const _hoisted_14 = { class: "col-9" }
const _hoisted_15 = { class: "card" }
const _hoisted_16 = { class: "card-body" }
const _hoisted_17 = { class: "card mb-5 mb-xl-10" }
const _hoisted_18 = { class: "card-body pt-9 pb-0" }
const _hoisted_19 = { class: "card-header card-header-stretch" }
const _hoisted_20 = {
  class: "nav nav-stretch nav-line-tabs fw-semobold border-0",
  role: "tablist",
  id: "kt_layout_builder_tabs",
  ref: "kt_layout_builder_tabs"
}
const _hoisted_21 = { class: "nav-item" }
const _hoisted_22 = { class: "nav-item" }
const _hoisted_23 = { class: "card-body" }
const _hoisted_24 = {
  class: "tab-content pt-3",
  id: "kt_tabs"
}
const _hoisted_25 = { class: "row position-relative mb-3" }
const _hoisted_26 = { class: "center fs-4 fw-semibold d-block" }
const _hoisted_27 = { class: "row" }
const _hoisted_28 = { class: "card overlay overflow-hidden" }
const _hoisted_29 = { class: "card-body p-0" }
const _hoisted_30 = { class: "overlay-wrapper" }
const _hoisted_31 = ["src"]
const _hoisted_32 = { class: "overlay-layer bg-dark bg-opacity-25" }
const _hoisted_33 = { class: "fs-4 fw-semibold d-block" }
const _hoisted_34 = { class: "row position-relative mb-3" }
const _hoisted_35 = { key: 0 }

import { ref, onMounted, inject, onUnmounted } from "vue";
import { useRoute } from "vue-router";
import DeviceInfo from "./components/DeviceInfo.vue";
import DeviceMap from "@/views/devices/DeviceMap.vue";
import StreamsDate from "./components/StreamsData";
import AuthImage from "@/components/AuthImage.vue";
import TucsonEventTable from "./components/TucsonEventTable.vue";
import EventVideo from "./components/TucsonEventVideo.vue";
// import { WSPusher } from "@/core/plugins/pusher";
// import { useToast } from "vue-toastification";
// import axios from "axios";
// const wsPusher: WSPusher = inject("wsPusher") as WSPusher;

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CameraIndex',
  setup(__props) {

const isLoading = ref(true);

const api: any = inject("api");
const tabIndex = ref(0);
const route = useRoute();
const device_id = ref(route.params.id);
const dynamicStreamUrl = ref("");
const deviceInfo = ref({});
const streamsData = StreamsDate[0];
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);
const otherViews = ref([]);
const tucsonViewImages = ref();

// const showEventType = ref("All");

const groupDevicesByNames = async (devices: Device[]) => {
  let deviceGroups: { [key: string]: Device[] } = {};
  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    let baseName = device.name.split(" ").slice(0, 3).join(" ");
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }
  intersectionGroups.value = deviceGroups;
};

const getOtherView = async () => {
  let baseName = deviceInfo.value.name.split(" ").slice(0, 3).join(" ");
  otherViews.value = intersectionGroups.value[baseName].filter(
    (device: Device) => device.device_id !== device_id.value
  );
  if (baseName.startsWith("Tucson")) {
    api["devices.getDeviceCurrentImage"]({
      device_id: otherViews.value[0].device_id,
    }).then((data) => {
      tucsonViewImages.value = "/device/image/" + data.data.image_id;
    });
  }
};

const switchDevice = async (show_device_id) => {
  device_id.value = show_device_id;
  intersectionDevices.value.forEach((device) => {
    if (device.device_id === show_device_id) {
      deviceInfo.value = device;
      findDeviceUUID(deviceInfo.value.name);
      groupDevicesByNames(intersectionDevices.value);
      getOtherView();
    }
  });
  const videoEl = document.querySelector("#mse-video");
  const mseUrl = dynamicStreamUrl.value;
  startPlay(videoEl, mseUrl);
  setActiveTab({ target: { getAttribute: () => "0" } });
};

const findDeviceUUID = (deviceName: string) => {
  for (const stream in streamsData) {
    if (streamsData[stream].name === deviceName) {
      dynamicStreamUrl.value = `wss://v.aiwaysion.com/stream/${stream}/channel/0/mse`;
      return;
    }
  }
};

const getAllDevicesInfo = async () => {
  await api["devices.getAllDevicesInfo"]().then((data) => {
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      }
      if (device.device_id === device_id.value) {
        deviceInfo.value = device;
        findDeviceUUID(deviceInfo.value.name);
      }
    });
    isLoading.value = false;
  });
};

onMounted(async () => {
  await getAllDevicesInfo();
  await groupDevicesByNames(intersectionDevices.value);
  await getOtherView();
  // fetchStreamsData();
  // findDeviceUUID(deviceInfo.value.name);
  tabIndex.value = 0; //parseInt(localStorage.getItem("builderTab") || "0");
  // subscribeCount();
  // init();
  const videoEl = document.querySelector("#mse-video");
  const mseUrl = dynamicStreamUrl.value;
  // const mseUrl = document.querySelector("#mse-url")?.value;
  startPlay(videoEl, mseUrl);
});

// close the websocket connection when the component is unmounted
// and remove all event listener
// and clea the buffer
onUnmounted(() => {
  if (ws) {
    ws.close();
  }
  if (mseSourceBuffer && mseSourceBuffer.updating) {
    mseSourceBuffer.abort();
  }
  if (mse && mse.readyState === "open") {
    mse.endOfStream();
  }
  mseSourceBuffer = null;
  mse = null;
  mseQueue.length = 0;
  mseStreamingStarted = false;
});

/**
 * Set active tab when the tab get clicked
 * @param event
 */
const setActiveTab = (event: {
  target: { getAttribute: (arg0: string) => string };
}) => {
  tabIndex.value = parseInt(event.target.getAttribute("data-tab-index"));
  // keep active tab
  // localStorage.setItem("builderTab", tabIndex.value.toString());
};

const mseQueue = [];
let mseSourceBuffer: SourceBuffer;
let mseStreamingStarted = false;
let mse: MediaSource;
let ws: WebSocket;

function startPlay(videoEl: Element | null, url: string | URL) {
  // Close any existing WebSocket connection
  if (ws) {
    ws.close();
  }

  // Cleanup existing MediaSource and SourceBuffer
  if (mse && mseSourceBuffer) {
    if (mseSourceBuffer.updating) {
      mseSourceBuffer.abort();
    }
    if (mse.readyState === "open") {
      mse.endOfStream();
    }
    mseSourceBuffer = undefined;
    mse = undefined;
    mseQueue.length = 0;
    mseStreamingStarted = false;
  }

  mse = new MediaSource();
  videoEl.src = window.URL.createObjectURL(mse);
  mse.addEventListener(
    "sourceopen",
    function () {
      ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";
      ws.onmessage = function (event) {
        const data = new Uint8Array(event.data);
        if (data[0] === 9) {
          let mimeCodec: string;
          const decodedArr = data.slice(1);
          if (window.TextDecoder) {
            mimeCodec = new TextDecoder("utf-8").decode(decodedArr);
          } else {
            mimeCodec = Utf8ArrayToStr(decodedArr);
          }
          mseSourceBuffer = mse.addSourceBuffer(
            'video/mp4; codecs="' + mimeCodec + '"'
          );
          mseSourceBuffer.mode = "segments";
          mseSourceBuffer.addEventListener("updateend", pushPacket);
        } else {
          readPacket(event.data);
        }
      };
    },
    false
  );
}

function pushPacket() {
  const videoEl = document.querySelector("#mse-video");
  let packet: undefined;

  if (!videoEl) {
    // If the video element is not available, exit the function
    return;
  }

  if (!mseSourceBuffer.updating) {
    if (mseQueue.length > 0) {
      packet = mseQueue.shift();
      mseSourceBuffer.appendBuffer(packet);
    } else {
      mseStreamingStarted = false;
    }
  }
  if (videoEl && videoEl.buffered.length > 0) {
    if (typeof document.hidden !== "undefined" && document.hidden) {
      // no sound, browser paused video without sound in background
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
    }
  }
}

function readPacket(packet: any) {
  if (!mseStreamingStarted) {
    mseSourceBuffer.appendBuffer(packet);
    mseStreamingStarted = true;
    return;
  }
  mseQueue.push(packet);
  if (!mseSourceBuffer.updating) {
    pushPacket();
  }
}

function Utf8ArrayToStr(array: string | any[] | Uint8Array) {
  var out: string, i: number, len: number, c: number;
  var char2: number, char3: number;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 7:
        out += String.fromCharCode(c);
        break;
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[2] || (_cache[2] = [
          _createElementVNode("h1", null, "Loading...", -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "card-header border-0" }, [
              _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Devices List")
            ], -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intersectionGroups.value, (intersection, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "accordion-item"
                    }, [
                      _createElementVNode("h2", {
                        class: "accordion-header",
                        id: 
                          'heading' +
                          String(index).replace(/\s+/g, '').toLowerCase()
                        
                      }, [
                        _createElementVNode("button", {
                          class: _normalizeClass(["accordion-button", { collapsed: index !== 0 }]),
                          type: "button",
                          "data-bs-toggle": "collapse",
                          "data-bs-target": 
                            '#collapse' +
                            String(index).replace(/\s+/g, '').toLowerCase()
                          ,
                          "aria-expanded": "true",
                          "aria-controls": 
                            'collapse' +
                            String(index).replace(/\s+/g, '').toLowerCase()
                          
                        }, _toDisplayString(index), 11, _hoisted_9)
                      ], 8, _hoisted_8),
                      _createElementVNode("div", {
                        id: 
                          'collapse' +
                          String(index).replace(/\s+/g, '').toLowerCase()
                        ,
                        class: _normalizeClass(["accordion-collapse collapse", { show: index === 0 }]),
                        "aria-labelledby": 'heading' + index,
                        "data-bs-parent": "#accordionExample"
                      }, [
                        _createElementVNode("div", _hoisted_11, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intersection, (item, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "d-flex align-items-center mb-5"
                            }, [
                              _createElementVNode("span", {
                                class: _normalizeClass(["bullet bullet-vertical h-40px", {
                                  'bg-success': item.status == 1,
                                  'bg-danger': item.status == 0,
                                  'bg-second': item.status == 2,
                                }])
                              }, null, 2),
                              _cache[3] || (_cache[3] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
                              _createElementVNode("div", _hoisted_12, [
                                _createVNode(_component_router_link, {
                                  to: {
                                    name: 'intersectionDetail',
                                    params: { id: item.device_id },
                                  },
                                  class: "text-gray-800 text-hover-primary fw-bold fs-6",
                                  onClick: ($event: any) => (switchDevice(item.device_id))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["to", "onClick"]),
                                _createElementVNode("span", _hoisted_13, _toDisplayString(item.updated_at), 1)
                              ])
                            ]))
                          }), 128))
                        ])
                      ], 10, _hoisted_10)
                    ]))
                  }), 128))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("div", _hoisted_18, [
                  _createVNode(DeviceInfo, { info: deviceInfo.value }, null, 8, ["info"]),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("ul", _hoisted_20, [
                      _createElementVNode("li", _hoisted_21, [
                        _createElementVNode("a", {
                          class: _normalizeClass(["nav-link", { active: tabIndex.value === 0 }]),
                          "data-bs-toggle": "tab",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (setActiveTab($event))),
                          "data-tab-index": "0",
                          href: "#kt_builder_overview",
                          role: "tab"
                        }, " Overview ", 2)
                      ]),
                      _createElementVNode("li", _hoisted_22, [
                        _createElementVNode("a", {
                          class: _normalizeClass(["nav-link", { active: tabIndex.value === 1 }]),
                          "data-bs-toggle": "tab",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (setActiveTab($event))),
                          "data-tab-index": "1",
                          href: "#kt_builder_events",
                          role: "tab"
                        }, " Features ", 2)
                      ])
                    ], 512)
                  ]),
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["tab-pane", { active: tabIndex.value === 0 }]),
                        id: "kt_builder_overview"
                      }, [
                        _createElementVNode("div", _hoisted_25, [
                          _cache[5] || (_cache[5] = _createElementVNode("video", {
                            id: "mse-video",
                            autoplay: "",
                            muted: "",
                            playsinline: "",
                            controls: "",
                            style: {"max-width":"100%","max-height":"100%"}
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_26, _toDisplayString(deviceInfo.value.name), 1)
                        ]),
                        _createElementVNode("div", _hoisted_27, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(otherViews.value, (intersection, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: index,
                              class: "col-lg-4"
                            }, [
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("div", _hoisted_29, [
                                  _createElementVNode("div", _hoisted_30, [
                                    (
                                        intersection.name.startsWith('Tucson')
                                      )
                                      ? (_openBlock(), _createBlock(AuthImage, {
                                          key: 0,
                                          url: tucsonViewImages.value,
                                          class: "w-100 rounded"
                                        }, null, 8, ["url"]))
                                      : (_openBlock(), _createElementBlock("img", {
                                          key: 1,
                                          src: 
                                        '/images/intersectionThumbnail/' +
                                        intersection.name +
                                        '.png'
                                      ,
                                          class: "w-100 rounded"
                                        }, null, 8, _hoisted_31))
                                  ]),
                                  _createElementVNode("div", _hoisted_32, [
                                    _createVNode(_component_router_link, {
                                      class: "btn btn-primary btn-shadow",
                                      to: {
                                        name: 'intersectionDetail',
                                        params: { id: intersection.device_id },
                                      },
                                      onClick: ($event: any) => (
                                        switchDevice(intersection.device_id)
                                      )
                                    }, {
                                      default: _withCtx(() => _cache[6] || (_cache[6] = [
                                        _createTextVNode("Play")
                                      ])),
                                      _: 2
                                    }, 1032, ["to", "onClick"])
                                  ]),
                                  _createElementVNode("div", _hoisted_33, _toDisplayString(intersection.name), 1)
                                ])
                              ])
                            ]))
                          }), 128))
                        ])
                      ], 2),
                      _createElementVNode("div", {
                        class: _normalizeClass(["tab-pane", { active: tabIndex.value === 1 }]),
                        id: "kt_builder_events"
                      }, [
                        _createElementVNode("div", null, [
                          _createVNode(TucsonEventTable)
                        ]),
                        _cache[7] || (_cache[7] = _createElementVNode("div", null, null, -1))
                      ], 2),
                      (tabIndex.value === 2)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["col-12 mt-5 mt-md-0", { active: tabIndex.value === 2 }]),
                            id: "kt_builder_map"
                          }, [
                            _createElementVNode("div", _hoisted_34, [
                              (
                              deviceInfo.value.name.startsWith(
                                'Bellevue Intersection 7'
                              )
                            )
                                ? (_openBlock(), _createElementBlock("div", _hoisted_35, _cache[8] || (_cache[8] = [
                                    _createStaticVNode("<div class=\"row\" data-v-3b7ac0e8><img class=\"col-lg-12\" src=\"http://35.233.176.29:3003/video_feed\" data-v-3b7ac0e8><p class=\"col-lg-12\" data-v-3b7ac0e8>Bellevue Intersection 7 E</p></div><div class=\"row\" data-v-3b7ac0e8><div class=\"col-lg-6\" data-v-3b7ac0e8><img width=\"100%\" src=\"http://35.233.176.29:3002/video_feed\" data-v-3b7ac0e8><p class=\"col-lg-12\" data-v-3b7ac0e8> Bellevue Intersection 7 W </p></div><div class=\"col-lg-6\" data-v-3b7ac0e8><img width=\"100%\" src=\"http://35.233.176.29:3004/video_feed\" data-v-3b7ac0e8><p class=\"col-lg-12\" data-v-3b7ac0e8> Bellevue Intersection 7 S </p></div></div>", 2)
                                  ])))
                                : (_openBlock(), _createBlock(DeviceMap, {
                                    key: 1,
                                    data: [deviceInfo.value]
                                  }, null, 8, ["data"]))
                            ])
                          ], 2))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})