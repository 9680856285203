import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { class: "card-header border-0 min-h-10px h-10px" }
const _hoisted_3 = {
  key: 0,
  class: "card-body"
}
const _hoisted_4 = {
  key: 1,
  class: "card-body"
}
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-5 border-end" }
const _hoisted_7 = { class: "mb-5" }
const _hoisted_8 = { class: "mb-5" }
const _hoisted_9 = { class: "col-7" }
const _hoisted_10 = { class: "row mb-3" }
const _hoisted_11 = { class: "col-6" }
const _hoisted_12 = { class: "col-6" }
const _hoisted_13 = { class: "device-status" }
const _hoisted_14 = { class: "row" }
const _hoisted_15 = { class: "col-6" }
const _hoisted_16 = {
  key: 0,
  class: "device-alert"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = { class: "col-6" }
const _hoisted_19 = { class: "input-group input-group-sm" }

import { onMounted, ref, inject, watch } from "vue";
import LoadingRing from "@/components/LoadingRing.vue";
import TimeShow from "@/components/TimeShow.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DeviceDetail',
  props: {
  detailId: { type: String, required: true },
  // item: { type: Object, required: true },
},
  emits: ["changeItem"],
  setup(__props, { emit: __emit }) {

const isLoading = ref(true);
const api = inject("api");

const item = ref(null);
const intervals = ref(0);

const props = __props;

const emits = __emit;

onMounted(() => {
  console.log(props.detailId);
  fetchData();
});

const fetchData = async () => {
  api["devices.getSuperDeviceDetail"]({ device_id: props.detailId }).then(
    (data) => {
      console.log("data: ", data.data);
      item.value = data.data;
      intervals.value = item.value.intervals;
      isLoading.value = false;
    }
  );
};

watch(
  () => props.detailId,
  () => {
    isLoading.value = true;
    item.value = null;
    fetchData();
  }
);

const changeStatus = (status) => {
  console.log("change status: ", status);
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { status: status },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};

const trunOffAlert = () => {
  console.log("trun off alert");
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { alert_at: null },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};

const updateIntervals = () => {
  console.log("updateIntervals: ", intervals);
  api["devices.updateDevice"]({
    device_id: props.detailId,
    data: { intervals: intervals.value },
  }).then((data) => {
    console.log("data: ", data.data);
    item.value = data.data;
    emits("changeItem", item.value);
    // isLoading.value = false;
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "text-muted",
        role: "button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, " < Back to devices list ")
    ]),
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(LoadingRing)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Name", -1)),
                _createElementVNode("div", null, _toDisplayString(item.value.name), 1)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _cache[6] || (_cache[6] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device ID", -1)),
                _createElementVNode("div", null, _toDisplayString(item.value.device_id), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[7] || (_cache[7] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Created at", -1)),
                _createElementVNode("div", null, [
                  _createVNode(TimeShow, {
                    "date-iso-string": item.value.created_at
                  }, null, 8, ["date-iso-string"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Type", -1)),
                  _createElementVNode("div", null, _toDisplayString(item.value.type), 1)
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Status", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", _hoisted_13, [
                      _createElementVNode("button", {
                        class: _normalizeClass(["device-status-offline", { 'bg-secondary': item.value.status == 0 }]),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (changeStatus(0)))
                      }, _toDisplayString(_ctx.$t("status.device_0")), 3),
                      _createElementVNode("button", {
                        class: _normalizeClass(["device-status-online", { 'bg-success': item.value.status == 1 }]),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (changeStatus(1)))
                      }, _toDisplayString(_ctx.$t("status.device_1")), 3),
                      _createElementVNode("button", {
                        class: _normalizeClass(["device-status-disable", { 'bg-warning': item.value.status == 2 }]),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (changeStatus(2)))
                      }, _toDisplayString(_ctx.$t("status.device_2")), 3)
                    ])
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Alert", -1)),
                  _createElementVNode("div", null, [
                    (item.value.alert_at != null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                          _createElementVNode("button", {
                            class: "device-alert-off",
                            onClick: trunOffAlert
                          }, " Trun off ")
                        ]))
                      : (_openBlock(), _createElementBlock("span", _hoisted_17, "No alert"))
                  ])
                ]),
                _createElementVNode("div", _hoisted_18, [
                  _cache[11] || (_cache[11] = _createElementVNode("div", { class: "fs-8 text-muted" }, "Device Alert intervals", -1)),
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_19, [
                      _withDirectives(_createElementVNode("input", {
                        type: "text",
                        class: "form-control",
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((intervals).value = $event)),
                        "aria-label": ""
                      }, null, 512), [
                        [_vModelText, intervals.value]
                      ]),
                      _createElementVNode("button", {
                        class: "btn btn-secondary",
                        type: "button",
                        id: "updateIntervalsBtn",
                        onClick: updateIntervals
                      }, " Update ")
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
  ]))
}
}

})