import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "" }

import { ref, onMounted } from "vue";
import StreamsDate from "./StreamsData";


// Define the emit function

export default /*@__PURE__*/_defineComponent({
  __name: 'MapViewVideoModal',
  props: {
  device: Object,
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const emit = __emit;
const props = __props;

const streamsData = StreamsDate[0];
const dynamicStreamUrl = ref("");
const mseQueue = [];
let mseSourceBuffer: SourceBuffer | null;
let mseStreamingStarted = false;
let mse: MediaSource | null = null;
let ws: WebSocket | null = null;

onMounted(() => {
  findDeviceUUID(props.device.name);
  startPlay(document.querySelector("#mse-video"), dynamicStreamUrl.value);
});

const handleClose = () => {
  stopLiveStream();
  emit("close");
};

const findDeviceUUID = (deviceName: string) => {
  for (const stream in streamsData) {
    if (streamsData[stream].name === deviceName) {
      dynamicStreamUrl.value = `wss://v.aiwaysion.com/stream/${stream}/channel/0/mse`;
      return;
    }
  }
};

function stopLiveStream() {
  // Immediately stop processing any more incoming messages
  if (ws) {
    ws.onmessage = null; // Remove the message handler
    ws.close();
    ws = null;
  }
  // Check if mse and sourceBuffer exist and are in a state that allows ending the stream
  if (
    mseSourceBuffer &&
    mse &&
    !mseSourceBuffer.updating &&
    mse.readyState === "open"
  ) {
    try {
      mse.endOfStream(); // Signal end of stream
    } catch (error) {
      console.error("Error ending media stream:", error);
    }
  }
  mse = null;
  mseSourceBuffer = null; // Clear the source buffer reference
  // Reset the video element
  const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
  if (videoEl) {
    videoEl.pause();
    videoEl.src = "";
    videoEl.load(); // Load with empty source to reset
  }
  // Clear any queued data
  mseQueue.length = 0;
}

function startPlay(videoEl: Element | null, url: string | URL) {
  // Close any existing WebSocket connection
  mse = new MediaSource();
  if (videoEl) {
    const videoEl = document.querySelector("#mse-video") as HTMLVideoElement;
    videoEl.src = window.URL.createObjectURL(mse);
  }
  mse.addEventListener(
    "sourceopen",
    function () {
      ws = new WebSocket(url);
      ws.binaryType = "arraybuffer";
      ws.onmessage = function (event) {
        const data = new Uint8Array(event.data);
        if (data[0] === 9) {
          let mimeCodec: string;
          const decodedArr = data.slice(1);
          if (window.TextDecoder) {
            mimeCodec = new TextDecoder("utf-8").decode(decodedArr);
          } else {
            mimeCodec = Utf8ArrayToStr(decodedArr);
          }
          mseSourceBuffer = mse.addSourceBuffer(
            'video/mp4; codecs="' + mimeCodec + '"'
          );
          mseSourceBuffer.mode = "segments";
          mseSourceBuffer.addEventListener("updateend", pushPacket);
        } else {
          readPacket(event.data);
        }
      };
    },
    false
  );
}

function pushPacket() {
  const videoEl = document.querySelector("#mse-video");
  let packet: undefined;

  if (!videoEl) {
    // If the video element is not available, exit the function
    return;
  }

  if (
    mse &&
    mseSourceBuffer &&
    !mseSourceBuffer.updating &&
    mseQueue.length > 0
  ) {
    const packet = mseQueue.shift();
    try {
      mseSourceBuffer.appendBuffer(packet);
    } catch (error) {
      console.error("Error appending buffer:", error);
      // Handle the error, potentially by stopping the stream or alerting the user
    }
  }

  if (!mseSourceBuffer.updating) {
    if (mseQueue.length > 0) {
      packet = mseQueue.shift();
      mseSourceBuffer.appendBuffer(packet);
    } else {
      mseStreamingStarted = false;
    }
  }
  if (videoEl && videoEl.buffered.length > 0) {
    if (typeof document.hidden !== "undefined" && document.hidden) {
      // no sound, browser paused video without sound in background
      videoEl.currentTime =
        videoEl.buffered.end(videoEl.buffered.length - 1) - 0.5;
    }
  }
}

function readPacket(packet: any) {
  if (!mseStreamingStarted) {
    mseSourceBuffer.appendBuffer(packet);
    mseStreamingStarted = true;
    return;
  }
  mseQueue.push(packet);
  if (!mseSourceBuffer.updating) {
    pushPacket();
  }
}

function Utf8ArrayToStr(array: string | any[] | Uint8Array) {
  var out: string, i: number, len: number, c: number;
  var char2: number, char3: number;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 7:
        out += String.fromCharCode(c);
        break;
      case 13:
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "close",
        onClick: handleClose
      }, "×"),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", null, _toDisplayString(__props.device.name), 1),
        _createVNode(_component_router_link, {
          to: {
            name: 'intersectionDetail',
            params: { id: __props.device.device_id },
          },
          class: "btn btn-primary hover-scale"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" More Info ")
          ])),
          _: 1
        }, 8, ["to"])
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("video", {
        id: "mse-video",
        autoplay: "",
        muted: "",
        playsinline: "",
        controls: "",
        style: {"max-width":"100%","max-height":"100%"}
      }, null, -1))
    ])
  ]))
}
}

})