import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, unref as _unref } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row mb-5 mb-xl-10 mx-0" }
const _hoisted_4 = { class: "col-12 col-lg-4" }
const _hoisted_5 = { class: "card p-5" }
const _hoisted_6 = { class: "card-title align-items-start flex-column" }
const _hoisted_7 = { class: "fw-bold fs-2x mb-3" }
const _hoisted_8 = { class: "fs-4" }
const _hoisted_9 = { class: "opacity-75" }
const _hoisted_10 = { class: "position-relative d-inline-block" }
const _hoisted_11 = {
  class: "table-container",
  style: {"max-height":"500px","overflow-y":"auto"}
}
const _hoisted_12 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { colspan: "8" }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = { colspan: "8" }
const _hoisted_17 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_18 = { key: 0 }
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "card card-xl-stretch mt-5 d-none d-lg-block" }
const _hoisted_21 = { class: "card-body d-flex align-items-center justify-content-between flex-wrap" }
const _hoisted_22 = { class: "col-12 col-md-6 col-lg-8 mt-5 mt-md-0" }
const _hoisted_23 = { class: "card card-xl-stretch mt-5 d-block d-lg-none" }
const _hoisted_24 = { class: "card-body d-flex align-items-center justify-content-between flex-wrap" }

import { ref, inject, onMounted } from "vue";
import MapView from "@/views/MapView";


const __default__ = {
  name: "main-dashboard",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const isLoading = ref(true);
const api = inject("api");
const devices = ref([]);
const onlineDevices = ref(0);
const offlineDevices = ref(0);
const disabledDevices = ref(0);

const getRouteName = (deviceName) => {
  const name = deviceName.toLowerCase();
  if (name.includes("intersection")) {
    return "intersectionDetail";
  } else if (name.includes("roadway")) {
    return "deviceDetail";
  } else if (name.includes("graffiti")) {
    return "graffitiDetail";
  } else {
    // Default route name if none of the keywords are found
    return "deviceDetail";
  }
};

const allDevicesInfos = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    devices.value = data.data;
    isLoading.value = false;
  });

  // api["devices.getDevices"]().then((data) => {
  //   console.log(data);
  //   data.data.forEach((device) => {
  //     devices.value.push(device);
  //     if (device.status === 1) {
  //       onlineDevices.value++;
  //     } else if (device.status === 0) {
  //       offlineDevices.value++;
  //     } else if (device.status === 2) {
  //       disabledDevices.value++;
  //     }
  //   });
  //   isLoading.value = false;
  // });
};

onMounted(() => {
  allDevicesInfos();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading data"))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("h3", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("title.Devices")), 1),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t("tips.youhave")), 1),
                  _createElementVNode("span", _hoisted_10, [
                    _createTextVNode(_toDisplayString(devices.value.length) + " " + _toDisplayString(_ctx.$t("devices.devices")) + " ", 1),
                    _cache[0] || (_cache[0] = _createElementVNode("span", { class: "position-absolute opacity-50 bottom-0 start-0 border-2 border-body border-bottom w-100" }, null, -1))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("table", _hoisted_12, [
                  _cache[3] || (_cache[3] = _createElementVNode("thead", null, [
                    _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase" }, [
                      _createElementVNode("th", { class: "" }, "N.O"),
                      _createElementVNode("th", { class: "" }, "Name"),
                      _createElementVNode("th", { class: "" }, "Status")
                    ])
                  ], -1)),
                  (isLoading.value)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_13, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", _hoisted_14, _toDisplayString(_ctx.$t("tips.loading")), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!isLoading.value && devices.value.length == 0)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_15, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("td", _hoisted_16, _toDisplayString(_ctx.$t("tips.nodata")), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (!isLoading.value && devices.value.length > 0)
                    ? (_openBlock(), _createElementBlock("tbody", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(devices.value, (device, index) => {
                          return (_openBlock(), _createElementBlock("tr", { key: index }, [
                            _createElementVNode("td", null, _toDisplayString(index), 1),
                            _createElementVNode("td", null, [
                              _createVNode(_component_router_link, {
                                to: {
                        name: getRouteName(device.name),
                        params: { id: device.device_id },
                      }
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(device.name), 1)
                                ]),
                                _: 2
                              }, 1032, ["to"])
                            ]),
                            (device.status == '1')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_18, _cache[1] || (_cache[1] = [
                                  _createElementVNode("div", { class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3" }, " Online ", -1)
                                ])))
                              : _createCommentVNode("", true),
                            (device.status == '2')
                              ? (_openBlock(), _createElementBlock("td", _hoisted_19, _cache[2] || (_cache[2] = [
                                  _createElementVNode("div", { class: "btn btn-sm btn-light fw-bold ms-2 fs-8 py-1 px-3" }, " Offline ", -1)
                                ])))
                              : _createCommentVNode("", true)
                          ]))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", _hoisted_21, [
                _cache[4] || (_cache[4] = _createElementVNode("div", { class: "me-2" }, [
                  _createElementVNode("h2", { class: "fw-bold text-gray-800 mb-3" }, "Any question?")
                ], -1)),
                _createVNode(_component_router_link, {
                  to: "/contact-us",
                  class: "btn btn-primary fw-semibold"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("title.ContactUs")), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_22, [
            _createVNode(_unref(MapView), { data: devices.value }, null, 8, ["data"])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("div", _hoisted_24, [
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "me-2" }, [
              _createElementVNode("h2", { class: "fw-bold text-gray-800 mb-3" }, "Any question?")
            ], -1)),
            _createVNode(_component_router_link, {
              to: "/contact-us",
              class: "btn btn-primary fw-semibold"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("title.ContactUs")), 1)
              ]),
              _: 1
            })
          ])
        ])
      ]))
}
}

})