import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  colspan: "100%",
  class: "text-center"
}
const _hoisted_3 = { class: "data-loading" }
const _hoisted_4 = {
  class: "mt-3",
  style: {"color":"gray"}
}
const _hoisted_5 = {
  key: 1,
  class: "data-loading"
}
const _hoisted_6 = {
  class: "mt-3",
  style: {"color":"gray"}
}

import MoonLoader from "vue-spinner/src/PulseLoader.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingRing',
  props: {
    width: { default: 50 },
    height: { default: 50 },
    isTable: { type: Boolean, default: false }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_ctx.isTable)
    ? (_openBlock(), _createElementBlock("tr", _hoisted_1, [
        _createElementVNode("td", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(MoonLoader, { color: '#ffc730' }),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("tips.loading")), 1)
          ])
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createVNode(MoonLoader, { color: '#ffc730' }),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("tips.loading")), 1)
      ]))
}
}

})