import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import Can from "@/core/plugins/ICan";

import SystemLayout from "@/layouts/SystemLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import MainLayout from "@/layouts/main-layout/MainLayout.vue";
import Dashboard from "@/views/Dashboard.vue";
import ProfileSetting from "@/views/profile/ProfileSetting.vue";

import DevicesIndex from "@/views/devices/DevicesIndex.vue";
import DeviceDetail from "@/views/devices/DeviceDetail.vue";
import AllDevicesIndex from "@/views/AllDevicesIndex.vue";

import CameraIndex from "@/views/intersection/CameraIndex.vue";
import IntersectionIndex from "@/views/intersection/IntersectionIndex.vue";
import IntersectionView from "@/views/intersection/IntersectionView.vue";

import ContactUs from "@/views/ContactUs.vue";

import ParkingIndex from "@/views/parking/ParkingIndex.vue";

import VRUIndex from "@/views/vru/VRUIndex.vue";

import GraffitiIndex from "@/views/graffiti/GraffitiIndex.vue";
import GraffitiDetail from "@/views/graffiti/GraffitiDetail.vue";

import AuthSignIn from "@/views/auth/SignIn.vue";
import AuthSignUp from "@/views/auth/SignUp.vue";
import AuthPasswordReset from "@/views/auth/PasswordReset.vue";
import Auth2FA from "@/views/auth/TwoFA.vue";
import AuthEmailVerification from "@/views/auth/EmailVerification.vue";

import ManageUsersIndex from "@/views/manage/users/UsersIndex.vue";
// import ManageUserDetail from "@/views/manage/users/UserDetail.vue";
import ManageAccountsIndex from "@/views/manage/accounts/AccountsIndex.vue";
import ManageDevicesIndex from "@/views/manage/devices/DevicesIndex.vue";

import Error403 from "@/views/errors/Error403.vue";
import Error404 from "@/views/errors/Error404.vue";
import Error500 from "@/views/errors/Error500.vue";

const baseRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: MainLayout,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          pageTitle: "title.Dashboard",
          breadcrumbs: ["title.Dashboard"],
          permissions: ["any"],
        },
      },
      {
        path: "/profile",
        name: "setting",
        component: ProfileSetting,
        meta: {
          pageTitle: "title.Setting",
          breadcrumbs: ["title.Setting"],
          permissions: ["any"],
        },
      },
      {
        path: "/devices",
        name: "devices",
        component: DevicesIndex,
        meta: {
          pageTitle: "title.Devices",
          breadcrumbs: ["title.Devices"],
          permissions: ["any"],
        },
      },
      {
        path: "/alldevices",
        name: "alldevices",
        component: AllDevicesIndex,
        meta: {
          pageTitle: "title.Devices",
          breadcrumbs: ["title.Devices"],
          permissions: ["any"],
        },
      },
      {
        path: "/devices/:id",
        name: "deviceDetail",
        component: DeviceDetail,
        meta: {
          pageTitle: "title.Devices",
          breadcrumbs: ["title.Devices"],
          permissions: ["any"],
        },
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: ContactUs,
        meta: {
          pageTitle: "title.ContactUs",
          breadcrumbs: ["title.ContactUs"],
          permissions: ["any"],
        },
      },
      {
        path: "/intersection",
        name: "intersection",
        component: IntersectionIndex,
        meta: {
          pageTitle: "title.Intersection",
          breadcrumbs: ["title.Intersection"],
          permissions: ["any"],
        },
      },
      {
        path: "/intersection/:name",
        name: "intersectionView",
        component: IntersectionView,
        meta: {
          pageTitle: "title.Intersection",
          breadcrumbs: ["title.Intersection"],
          permissions: ["any"],
        },
      },
      {
        path: "/intersection/:id",
        name: "intersectionDetail",
        component: CameraIndex,
        meta: {
          pageTitle: "title.Intersection",
          breadcrumbs: ["title.Intersection"],
          permissions: ["any"],
        },
      },
      {
        path: "/graffiti",
        name: "graffiti",
        component: GraffitiIndex,
        meta: {
          pageTitle: "title.GraffitiDetection",
          breadcrumbs: ["title.GraffitiDetection"],
          permissions: ["any"],
        },
      },
      {
        path: "/graffiti/:id",
        name: "graffitiDetail",
        component: GraffitiDetail,
        meta: {
          pageTitle: "title.GraffitiDetection",
          breadcrumbs: ["title.GraffitiDetection"],
          permissions: ["any"],
        },
      },
      {
        path: "/parking",
        name: "parking",
        component: ParkingIndex,
        meta: {
          pageTitle: "title.Parking",
          breadcrumbs: ["title.Parking"],
          permissions: ["any"],
        },
      },
      {
        path: "/vru",
        name: "vru",
        component: VRUIndex,
        meta: {
          pageTitle: "title.VRU",
          breadcrumbs: ["title.VRU"],
          permissions: ["any"],
        },
      },
      {
        path: "/manage/devices",
        name: "ManageDevices",
        component: ManageDevicesIndex,
        meta: {
          pageTitle: "title.ManageDevices",
          breadcrumbs: ["title.ManageDevices"],
          permissions: ["manage"],
        },
      },
      {
        path: "/manage/devices/:device_id",
        name: "ManageDeviceDetail",
        component: ManageDevicesIndex,
        meta: {
          pageTitle: "title.Devices",
          breadcrumbs: ["title.Devices"],
          permissions: ["manage"],
        },
      },
      {
        path: "/manage/accounts",
        name: "ManageAccounts",
        component: ManageAccountsIndex,
        meta: {
          pageTitle: "title.ManageAccounts",
          breadcrumbs: ["title.ManageAccounts"],
          permissions: ["manage"],
        },
      },
      {
        path: "/manage/users",
        name: "ManageUsers",
        component: ManageUsersIndex,
        meta: {
          pageTitle: "title.ManageUsers",
          breadcrumbs: ["title.ManageUsers"],
          permissions: ["manage"],
        },
      },
      {
        path: "/manage/users/:id",
        name: "ManageUserDetail",
        component: ManageUsersIndex,
        meta: {
          pageTitle: "title.Users",
          breadcrumbs: ["title.Users"],
          permissions: ["manage"],
        },
      },
    ],
  },
];

const authRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: AuthLayout,
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: AuthSignIn,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: AuthSignUp,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: AuthPasswordReset,
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/2fa",
        name: "2fa",
        component: Auth2FA,
      },
    ],
  },
];
const systemRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: SystemLayout,
    children: [
      {
        path: "/email-verification",
        name: "email-verification",
        component: AuthEmailVerification,
      },
    ],
  },
];

const errorRoutes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: MainLayout,
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: Error404,
      },
      {
        // the 403 route, when none of the above matches
        path: "/403",
        name: "403",
        component: Error403,
      },
      {
        path: "/500",
        name: "500",
        component: Error500,
      },
    ],
  },
];
const routes: Array<RouteRecordRaw> = baseRoutes.concat(
  authRoutes,
  systemRoutes,
  errorRoutes
);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);
  await store.dispatch(Actions.VERIFY_AUTH);
  if (to.meta == undefined || to.meta.permissions == undefined) {
    next();
  } else {
    if (Can.cans(to.meta.permissions as unknown as string[])) {
      next();
    } else {
      next({ name: "403" });
    }
  }

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
