<template>
  <!--begin::Accordion-->
  <div class="accordion" id="kt_accordion_1">
    <div v-for="event in events" :key="event.id" class="accordion-item">
      <h2 class="accordion-header" :id="'kt_accordion_1_header_' + event.id">
        <button
          class="accordion-button fs-4 fw-semibold collapsed"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="'#kt_accordion_1_body_' + event.id"
          aria-expanded="false"
          :aria-controls="'kt_accordion_1_body_' + event.id"
          @click="handleAccordionClick(event)"
        >
          Event Date: {{ event.create_time }}
        </button>
      </h2>
      <div
        :id="'kt_accordion_1_body_' + event.id"
        class="accordion-collapse collapse"
        :aria-labelledby="'kt_accordion_1_header_' + event.id"
        data-bs-parent="#kt_accordion_1"
      >
        <div class="accordion-body">
          <div>Event Start Time: {{ event.create_time }}</div>
          <div>Event Duration: {{ getEventDuration(event) }}</div>
          <div>Number of Snapshots took: {{ event.image_ids.length }}</div>
          <div v-if="loadingEventId === event.id">
            <div class="spinner"></div>
          </div>
          <div v-else-if="event.images">
            <Carousel v-bind="carouselConfig">
              <Slide v-for="(image, index) in event.images" :key="index">
                <img
                  :src="image.src"
                  :alt="image.filename"
                  class="carousel__item"
                />
              </Slide>

              <template #addons>
                <Navigation />
                <!-- <Pagination /> -->
              </template>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
    <SpokaneEventVideo
      v-if="deviceid == '62946862-daa3-11ef-8dd8-42010a8a000d'"
    />
  </div>
  <!--end::Accordion-->
</template>

<script setup>
import axios from "axios";
import "vue3-carousel/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import { ref, onMounted, toRefs, watch } from "vue";
import SpokaneEventVideo from "./SpokaneEventVideo.vue";

const events = ref([]);
const imagesLoaded = ref({});
const loadingEventId = ref(null);

const props = defineProps({
  deviceid: String,
});
const { deviceid } = toRefs(props);
watch(deviceid, () => {
  fetchDeviceEvents();
});

onMounted(() => {
  fetchDeviceEvents();
});

const carouselConfig = {
  navigation: true,
  loop: false,
  perPage: 1,
  perPageCustom: {
    0: 1,
    768: 1,
    1024: 1,
  },
};

const fetchDeviceEvents = async () => {
  try {
    const response = await axios.get(
      // "http://34.83.39.108:4000/devices/2/events"
      "https://aiwaysionapi.com/graffiti/devices/" + deviceid.value + "/events"
    );
    events.value = response.data.data;
  } catch (error) {
    console.error("Error fetching device events:", error);
  }
};

const fetchEventImages = async (event) => {
  loadingEventId.value = event.id;
  try {
    const response = await axios.post(
      "https://aiwaysionapi.com/graffiti/get-images",
      {
        links: event.image_ids,
      }
    );
    // Assuming the response contains an array of base64-encoded images
    event.images = response.data.map((img) => ({
      src: `data:image/jpeg;base64,${img.content}`,
      filename: img.filename,
    }));
    imagesLoaded.value[event.id] = true;
  } catch (error) {
    console.error("Error fetching event images:", error);
  } finally {
    loadingEventId.value = null;
  }
};

const handleAccordionClick = (event) => {
  if (!imagesLoaded.value[event.id]) {
    fetchEventImages(event);
  }
};

const getEventDuration = (event) => {
  const startTime = new Date(event.create_time);
  const endTime = new Date(event.last_modified);
  const duration = (endTime - startTime) / 1000 / 60;
  return `${(duration + 3).toFixed(1)} minutes`;
};
</script>

<style scoped>
img {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #000;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
