import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "container pt-3 pb-5" }
const _hoisted_2 = { class: "card p-5" }
const _hoisted_3 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { colspan: "8" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { colspan: "8" }
const _hoisted_8 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}

import { ref, onMounted, inject } from "vue";

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IntersectionIndex',
  setup(__props) {

const isLoading = ref(true);
const api: any = inject("api");

const allDevices = ref([]);
const roadwayDevices: any = ref([]);
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);

const getDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      } else if (device.type === "1") {
        roadwayDevices.value.push(device);
      }
    });
    intersectionGroups.value = groupDevicesByNames(intersectionDevices.value);
    isLoading.value = false;
  });
};

function groupDevicesByNames(devices: Device[]): { [key: string]: Device[] } {
  const deviceGroups: { [key: string]: Device[] } = {};

  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    const baseName = device.name.split(" ").slice(0, 3).join(" ");

    // If the base name is already a key in deviceGroups, append the current name
    // If not, create a new key with the current name as the initial value
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }

  return deviceGroups;
}

onMounted(() => {
  getDevices();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("table", _hoisted_3, [
        _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
          _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
            _createElementVNode("th", { class: "" }, "Name"),
            _createElementVNode("th", { class: "" }, "Location")
          ])
        ], -1)),
        (isLoading.value)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_4, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_5, _toDisplayString(_ctx.$t("tips.loading")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (!isLoading.value && intersectionDevices.value.length == 0)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_6, [
              _createElementVNode("tr", null, [
                _createElementVNode("td", _hoisted_7, _toDisplayString(_ctx.$t("tips.nodata")), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (!isLoading.value && intersectionDevices.value.length > 0)
          ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((intersectionGroups.value as any), (intersection, index) => {
                return (_openBlock(), _createElementBlock("tr", { key: index }, [
                  _createElementVNode("td", null, [
                    _createVNode(_component_router_link, {
                      to: {
                  name: 'intersectionView',
                  params: {
                    name: intersection[0].name.split(' ').slice(0, 3).join(''),
                  },
                }
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(index), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"])
                  ]),
                  _createElementVNode("td", null, _toDisplayString(intersection[0].location), 1)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})