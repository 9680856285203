import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = {
  key: 1,
  class: "symbol symbol-40px overflow-hidden"
}

import { ref, watch } from "vue";
import JwtService from "@/core/services/JwtService";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserAvatar',
  props: {
  avatar: { type: String, required: true },
  name: { type: String, required: true },
  size: { type: String, default: "40px" },
},
  setup(__props) {

const props = __props;
const src = ref("");
const avatarLetter = ref("");
const avatarClass = ref("symbol-" + props.size);
const letterAvatarColor = ref("");
// [
//   "bg-light-primary",
//   "bg-light-danger",
//   "bg-light-success",
//   "bg-light-warning",
//   "bg-light-info",
//   "bg-light-dark",
// ];

const processImageUrl = (url: string) => {
  let img_src = "";
  if (url == "no-image") img_src = "/images/no-image.png";
  if (url == "no-avatar") {
    img_src = "";
    avatarLetter.value = props.name.charAt(0).toUpperCase();
    if (["A", "B", "C", "D", "E"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-primary";
    } else if (["F", "G", "H", "I", "J"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-danger";
    } else if (["K", "L", "M", "N", "O"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-success";
    } else if (["P", "Q", "R", "S", "T"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-warning";
    } else if (["U", "V", "W", "X", "Y"].includes(avatarLetter.value)) {
      letterAvatarColor.value = "text-info";
    } else {
      letterAvatarColor.value = "text-dark";
    }
  } else {
    img_src =
      process.env.VUE_APP_API_URL +
      url +
      "?access_token=" +
      JwtService.getToken();
  }
  return img_src;
};

src.value = processImageUrl(props.avatar);

watch(
  () => props.avatar,
  function () {
    src.value = processImageUrl(props.avatar);
  }
);

return (_ctx: any,_cache: any) => {
  return (src.value !== '')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["symbol avatar-bg", avatarClass.value])
      }, [
        _createElementVNode("img", {
          src: src.value,
          alt: __props.name
        }, null, 8, _hoisted_1)
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", {
          class: _normalizeClass(["symbol-label fs-3 bg-light-danger", letterAvatarColor.value])
        }, _toDisplayString(avatarLetter.value), 3)
      ]))
}
}

})