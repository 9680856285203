import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass } from "vue"

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'UserRole',
  props: {
  roles: { type: Array, required: true },
},
  setup(__props) {

const props = __props;
const badgeClass = ref({
  super: "badge-danger",
  admin: "badge-light-danger",
  manage: "badge-light-success",
  user: "badge-light-primary",
  guest: "badge-light-info",
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.roles, (role, index) => {
    return (_openBlock(), _createElementBlock("span", {
      class: _normalizeClass(["badge fw-bold fs-8 px-2 py-1 ms-2", badgeClass.value[role]]),
      key: index
    }, _toDisplayString(_ctx.$t("roles." + role)), 3))
  }), 128))
}
}

})