import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "main-wrapper mb-5" }
const _hoisted_2 = { class: "form-wrapper mb-10" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "mb-5" }
const _hoisted_6 = { class: "mb-5" }
const _hoisted_7 = { class: "mb-5" }

import { ref, inject } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContactUs',
  setup(__props) {

const api = inject("api");

const data = ref({
  name: "",
  email: "",
  message: "",
});

const status = ref(false);

const submit = () => {
  api["contact.contact"]({ data: data.value }).then((res) => {
    if (res.data == "ok") {
      status.value = true;
    }
    console.log(res);
  });
  console.log("submit: ", data.value);
  return false;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _withModifiers(submit, ["prevent"]),
        method: "POST"
      }, [
        _cache[8] || (_cache[8] = _createElementVNode("h1", { class: "mb-4" }, "Contact Us", -1)),
        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "mb-8" }, " Got a technical issue? Want to send feedback about our features? details about our Business plan? Let us know. ", -1)),
        (status.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[3] || (_cache[3] = [
              _createElementVNode("h4", { class: "mb-10 text-center font-weight-bold" }, " Submitted successfully! ", -1)
            ])))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[4] || (_cache[4] = _createElementVNode("label", {
                  for: "name",
                  class: "form-label"
                }, " Full Name ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "text",
                  name: "name",
                  id: "name",
                  minlength: "2",
                  maxlength: "20",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((data.value.name) = $event)),
                  placeholder: "Full Name",
                  class: "form-control form-control-solid"
                }, null, 512), [
                  [_vModelText, data.value.name]
                ])
              ]),
              _createElementVNode("div", _hoisted_6, [
                _cache[5] || (_cache[5] = _createElementVNode("label", {
                  for: "email",
                  class: "form-label"
                }, " Email Address ", -1)),
                _withDirectives(_createElementVNode("input", {
                  type: "email",
                  name: "email",
                  id: "email",
                  minlength: "2",
                  maxlength: "20",
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((data.value.email) = $event)),
                  placeholder: "Enter your email",
                  class: "form-control form-control-solid"
                }, null, 512), [
                  [_vModelText, data.value.email]
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _cache[6] || (_cache[6] = _createElementVNode("label", {
                  for: "message",
                  class: "form-label"
                }, " Message ", -1)),
                _withDirectives(_createElementVNode("textarea", {
                  rows: "6",
                  name: "message",
                  id: "message",
                  minlength: "10",
                  maxlength: "200",
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((data.value.message) = $event)),
                  placeholder: "Leave your message",
                  class: "form-control form-control-solid"
                }, null, 512), [
                  [_vModelText, data.value.message]
                ])
              ]),
              _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                _createElementVNode("button", { class: "btn btn-primary mb-10" }, "Submit")
              ], -1))
            ]))
      ], 32)
    ])
  ]))
}
}

})