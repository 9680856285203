import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-lg-500px p-10" }
const _hoisted_2 = { class: "fv-row mb-10" }
const _hoisted_3 = { class: "fv-plugins-message-container" }
const _hoisted_4 = { class: "fv-help-block" }
const _hoisted_5 = { class: "fv-row mb-10" }
const _hoisted_6 = { class: "d-flex flex-stack mb-2" }
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = {
  tabindex: "3",
  type: "submit",
  ref: "submitButton",
  id: "kt_sign_in_submit",
  class: "btn btn-lg btn-primary w-100 mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      class: "form w-100",
      id: "kt_login_signin_form",
      onSubmit: _ctx.onSubmitLogin,
      "validation-schema": _ctx.login
    }, {
      default: _withCtx(() => [
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "text-center mb-10" }, [
          _createElementVNode("h1", { class: "text-dark mb-3" }, "Sign In")
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _cache[0] || (_cache[0] = _createElementVNode("label", { class: "form-label fs-6 fw-bold text-dark" }, "Email", -1)),
          _createVNode(_component_Field, {
            tabindex: "1",
            class: "form-control form-control-lg form-control-solid",
            type: "text",
            name: "email",
            autocomplete: "off"
          }),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_ErrorMessage, { name: "email" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _cache[2] || (_cache[2] = _createElementVNode("label", { class: "form-label fw-bold text-dark fs-6 mb-0" }, "Password", -1)),
            _createVNode(_component_router_link, {
              to: "/password-reset",
              class: "link-primary fs-6 fw-bold"
            }, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode(" Forgot Password ? ")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_Field, {
            tabindex: "2",
            class: "form-control form-control-lg form-control-solid",
            type: "password",
            name: "password",
            autocomplete: "off"
          }),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_ErrorMessage, { name: "password" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("button", _hoisted_10, _cache[3] || (_cache[3] = [
            _createElementVNode("span", { class: "indicator-label" }, " Continue ", -1),
            _createElementVNode("span", { class: "indicator-progress" }, [
              _createTextVNode(" Please wait... "),
              _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
            ], -1)
          ]), 512)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ]))
}