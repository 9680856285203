import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-3" }
const _hoisted_5 = { class: "card" }
const _hoisted_6 = { class: "card-body pt-2" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "col-9" }
const _hoisted_9 = { class: "card" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "card" }
const _hoisted_12 = { class: "card-body pt-9 pb-0" }
const _hoisted_13 = { class: "d-flex flex-column" }
const _hoisted_14 = { class: "d-flex align-items-center mb-2" }
const _hoisted_15 = { class: "text-gray-800 fs-2 fw-bold me-1" }
const _hoisted_16 = {
  key: 0,
  class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_17 = {
  key: 1,
  class: "btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_18 = { class: "d-flex fw-semobold fs-6 mb-2 pe-2 vstack" }
const _hoisted_19 = { class: "d-flex align-items-center me-5 mb-2" }
const _hoisted_20 = { class: "d-flex align-items-center me-5 mb-2" }
const _hoisted_21 = { class: "card-body" }
const _hoisted_22 = { class: "col-12 mt-5 mt-md-0" }

import { ref, onMounted, inject } from "vue";
import { useRoute } from "vue-router";
import IntersectionMapView from "@/views/intersection/components/IntersectionMapView.vue";

type Device = {
  device_id: string;
  name: string;
  ip: string;
  location: string;
  latitude: string;
  longitude: string;
  status: number;
  type: string;
  created_at: string;
  updated_at: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'IntersectionView',
  setup(__props) {

const route = useRoute();
const api: any = inject("api");

const isLoading = ref(true);
const deviceName = ref(route.params.name);
const allDevices = ref([]);
const intersectionDevices: any = ref([]);
const intersectionGroups: any = ref([]);
const currentIntersection: any = ref([]);
const mapViewKey = ref(0);

const getAllDevices = async () => {
  api["devices.getAllDevicesInfo"]().then((data) => {
    allDevices.value = data.data;
    data.data.forEach((device) => {
      if (device.type === "2") {
        intersectionDevices.value.push(device);
      }
    });
    intersectionGroups.value = groupDevicesByNames(intersectionDevices.value);
    currentIntersection.value = getCurrentIntersection(
      intersectionGroups.value
    );
    isLoading.value = false;
  });
};

function groupDevicesByNames(devices: Device[]): { [key: string]: Device[] } {
  const deviceGroups: { [key: string]: Device[] } = {};
  for (const device of devices) {
    // Extract the base name (e.g., "Bellevue Intersection 1" from "Bellevue Intersection 1 E")
    const baseName = device.name.split(" ").slice(0, 3).join(" ");
    // If the base name is already a key in deviceGroups, append the current name
    // If not, create a new key with the current name as the initial value
    deviceGroups[baseName] = deviceGroups[baseName]
      ? [...deviceGroups[baseName], device]
      : [device];
  }
  return deviceGroups;
}

// loop through the intersectionGroups to get the intersection that matches the deviceName
function getCurrentIntersection(intersectionGroups: any) {
  for (const intersection in intersectionGroups) {
    if (intersection.split(" ").slice(0, 3).join("") === deviceName.value) {
      return intersectionGroups[intersection];
    }
  }
}

const showDevice = async (devices: Device[]) => {
  currentIntersection.value = devices;
  mapViewKey.value += 1;
};

onMounted(async () => {
  getAllDevices();
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
          _createElementVNode("h1", null, "Loading...", -1)
        ])))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _cache[3] || (_cache[3] = _createElementVNode("div", { class: "card-header border-0" }, [
                  _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Intersection List")
                ], -1)),
                _createElementVNode("div", _hoisted_6, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(intersectionGroups.value, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      class: "d-flex align-items-center mb-5"
                    }, [
                      _cache[1] || (_cache[1] = _createElementVNode("span", { class: "bullet bg-success bullet-vertical h-40px" }, null, -1)),
                      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "form-check form-check-custom form-check-solid mx-5" }, null, -1)),
                      _createElementVNode("div", _hoisted_7, [
                        _createVNode(_component_router_link, {
                          to: 
                        '/intersection/' +
                        item[0].name.split(' ').slice(0, 3).join('')
                      ,
                          class: "text-gray-800 text-hover-primary fw-bold fs-6",
                          onClick: ($event: any) => (showDevice(item))
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(index), 1)
                          ]),
                          _: 2
                        }, 1032, ["to", "onClick"])
                      ])
                    ]))
                  }), 128))
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, [
                          _createElementVNode("div", _hoisted_15, _toDisplayString(currentIntersection.value[0].name), 1),
                          (currentIntersection.value[0].status == '1')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_16, " Online "))
                            : (_openBlock(), _createElementBlock("div", _hoisted_17, " Offline "))
                        ]),
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("div", _hoisted_19, " Location: " + _toDisplayString(currentIntersection.value[0].location), 1),
                          _createElementVNode("div", _hoisted_20, " Installed at: " + _toDisplayString(currentIntersection.value[0].created_at), 1)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      (_openBlock(), _createBlock(IntersectionMapView, {
                        data: currentIntersection.value,
                        key: mapViewKey.value
                      }, null, 8, ["data"]))
                    ])
                  ])
                ])
              ])
            ])
          ])
        ]))
  ]))
}
}

})