import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

import { ref } from "vue";
import Clipboard from "clipboard";


export default /*@__PURE__*/_defineComponent({
  __name: 'CopyBox',
  props: {
  val: String,
  hasIcon: { type: Boolean, required: false },
  hasAni: { type: Boolean, required: false },
},
  setup(__props, { expose: __expose }) {

const props = __props;

const showTip = ref(false);

const copy = () => {
  Clipboard.copy(props.val as string);
  showTip.value = true;
  setTimeout(() => {
    showTip.value = false;
  }, 1000);
};

__expose({
  copy,
});

return (_ctx: any,_cache: any) => {
  const _component_inline_svg = _resolveComponent("inline-svg")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["position-relative cursor-pointer", { 'pe-3': __props.hasIcon }]),
    onClick: copy
  }, [
    _createTextVNode(_toDisplayString(props.val) + " ", 1),
    (__props.hasIcon)
      ? (_openBlock(), _createBlock(_component_inline_svg, {
          key: 0,
          style: {"margin-top":"-8px"},
          src: "/images/icons/general/gen054.svg"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["tip fs-8 badge badge-light", { 'show-tip-animation': showTip.value }])
    }, _toDisplayString(_ctx.$t("tips.copied2Clipboard")), 3)
  ], 2))
}
}

})