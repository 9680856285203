import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "row pt-3 pb-5" }
const _hoisted_2 = {
  key: 0,
  class: "col-12"
}
const _hoisted_3 = { class: "card p-5" }
const _hoisted_4 = { class: "card-header border-0" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = { class: "card-body pt-2" }
const _hoisted_7 = {
  class: "table align-middle table-row-dashed fs-6 gy-5",
  id: "table_accounts_requests"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { colspan: "7" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { colspan: "7" }
const _hoisted_12 = {
  key: 2,
  class: "text-gray-600 fw-semibold"
}
const _hoisted_13 = {
  key: 1,
  class: "col-3"
}
const _hoisted_14 = { class: "card card-xl-stretch mb-xl-8" }
const _hoisted_15 = { class: "card-header border-0" }
const _hoisted_16 = { class: "card-toolbar" }
const _hoisted_17 = { class: "card-body pt-2" }
const _hoisted_18 = { class: "" }
const _hoisted_19 = {
  key: 0,
  class: "badge bg-danger fw-bold fs-8 px-2 py-1 ms-2"
}
const _hoisted_20 = { class: "" }
const _hoisted_21 = {
  key: 2,
  class: "col-9"
}
const _hoisted_22 = {
  key: 3,
  class: "col-9"
}

import { ref, onMounted, inject } from "vue";
import { useRouter } from "vue-router";
import DeviceDetailView from "./DeviceDetail.vue";
import DeviceCreateView from "./DeviceCreate.vue";
import TimeShow from "@/components/TimeShow.vue";
import CopyBox from "@/components/CopyBox.vue";
import StatusBadge from "@/components/StatusBadge.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DevicesIndex',
  setup(__props) {

const router = useRouter();
const isLoading = ref(true);
const api = inject("api");
const items = ref([]);
const isShowDetail = ref(false);
const isShowCreate = ref(false);
const detailId = ref(null);

const fetchData = async () => {
  api["devices.getAllDevices"]().then((data) => {
    console.log("data: ", data.data.data);
    items.value = data.data.data;
    isLoading.value = false;
  });
};

const showDetail = (id) => {
  detailId.value = id;
  isShowCreate.value = false;
  isShowDetail.value = true;
};

const hideDetail = () => {
  isShowDetail.value = false;
  detailId.value = null;
};

const showCreate = () => {
  isShowCreate.value = true;
  isShowDetail.value = false;
};

const hideCreate = () => {
  isShowCreate.value = false;
};

onMounted(() => {
  console.log("params: ", router.currentRoute.value.params);
  if (router.currentRoute.value.params.device_id != undefined) {
    if (router.currentRoute.value.params.device_id == "create") {
      isShowCreate.value = true;
    } else {
      showDetail(router.currentRoute.value.params.device_id);
    }
  }
  fetchData();
});

const changeItem = (item) => {
  console.log("changeItem: ", item);
  const index = items.value.findIndex((i) => i.device_id == item.device_id);
  console.log("index: ", index);
  if (index != -1) {
    items.value[index] = item;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isShowDetail.value && !isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _cache[3] || (_cache[3] = _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Devices", -1)),
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_router_link, {
                  to: {
                name: 'ManageDeviceDetail',
                params: { device_id: 'create' },
              },
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showCreate()))
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("Create")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("table", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("thead", null, [
                  _createElementVNode("tr", { class: "text-start text-muted fw-bold fs-7 text-uppercase gs-0" }, [
                    _createElementVNode("th", { class: "" }, "ID"),
                    _createElementVNode("th", { class: "" }, "Status"),
                    _createElementVNode("th", { class: "" }, "Device Name"),
                    _createElementVNode("th", { class: "" }, "Device ID"),
                    _createElementVNode("th", { class: "" }, "Location"),
                    _createElementVNode("th", { class: "" }, "Created At"),
                    _createElementVNode("th", { class: "" }, "Action")
                  ])
                ], -1)),
                (isLoading.value)
                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_8, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t("tips.loading")), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!isLoading.value && items.value.length == 0)
                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_10, [
                      _createElementVNode("tr", null, [
                        _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t("tips.nodata")), 1)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!isLoading.value && items.value.length > 0)
                  ? (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                        return (_openBlock(), _createElementBlock("tr", { key: index }, [
                          _createElementVNode("td", null, _toDisplayString(item.id), 1),
                          _createElementVNode("td", null, [
                            _createVNode(StatusBadge, {
                              status: 'device_' + item.status
                            }, null, 8, ["status"]),
                            (item.alert_at != null)
                              ? (_openBlock(), _createBlock(StatusBadge, {
                                  key: 0,
                                  status: 'alert',
                                  class: "ms-3"
                                }))
                              : _createCommentVNode("", true)
                          ]),
                          _createElementVNode("td", null, _toDisplayString(item.name), 1),
                          _createElementVNode("td", null, [
                            _createVNode(CopyBox, {
                              val: item.device_id,
                              hasAni: true,
                              hasIcon: true
                            }, null, 8, ["val"])
                          ]),
                          _createElementVNode("td", null, _toDisplayString(item.location), 1),
                          _createElementVNode("td", null, [
                            _createVNode(TimeShow, {
                              "date-iso-string": item.created_at
                            }, null, 8, ["date-iso-string"])
                          ]),
                          _createElementVNode("td", null, [
                            _createVNode(_component_router_link, {
                              to: {
                      name: 'ManageDeviceDetail',
                      params: { device_id: item.device_id },
                    },
                              onClick: ($event: any) => (showDetail(item.device_id)),
                              class: "btn btn-sm btn-light btn-active-light-primary"
                            }, {
                              default: _withCtx(() => _cache[4] || (_cache[4] = [
                                _createTextVNode(" Detail ")
                              ])),
                              _: 2
                            }, 1032, ["to", "onClick"])
                          ])
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (isShowDetail.value || isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _cache[7] || (_cache[7] = _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Deivces", -1)),
              _createElementVNode("div", _hoisted_16, [
                _createVNode(_component_router_link, {
                  to: {
                name: 'ManageDeviceDetail',
                params: { device_id: 'create' },
              },
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (showCreate()))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode("Create")
                  ])),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.value, (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: _normalizeClass({ 'mb-7': items.value.length - 1 !== index })
                }, [
                  _createElementVNode("div", _hoisted_18, [
                    _createTextVNode(_toDisplayString(item.name) + " ", 1),
                    _createVNode(StatusBadge, {
                      status: 'device_' + item.status
                    }, null, 8, ["status"]),
                    (item.alert_at != null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_19, "Alert"))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createVNode(_component_router_link, {
                      to: {
                    name: 'ManageDeviceDetail',
                    params: { device_id: item.device_id },
                  },
                      onClick: ($event: any) => (showDetail(item.device_id))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.device_id), 1)
                      ]),
                      _: 2
                    }, 1032, ["to", "onClick"])
                  ])
                ], 2))
              }), 128))
            ])
          ])
        ]))
      : _createCommentVNode("", true),
    (isShowDetail.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createVNode(DeviceDetailView, {
            detailId: detailId.value,
            onClose: hideDetail,
            onChangeItem: changeItem
          }, null, 8, ["detailId"])
        ]))
      : _createCommentVNode("", true),
    (isShowCreate.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
          _createVNode(DeviceCreateView, { onClose: hideCreate })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})