import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "kt_app_toolbar",
  class: "app-toolbar py-3 py-lg-6"
}

import { toolbarWidthFluid } from "@/core/helpers/config";
import KTPageTitle from "@/layouts/main-layout/toolbar/PageTitle.vue";

const __default__ = {
  name: "layout-toolbar",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      id: "kt_app_toolbar_container",
      class: _normalizeClass(["app-container d-flex flex-stack", {
        'container-fluid': _unref(toolbarWidthFluid),
        'container-xxl': !_unref(toolbarWidthFluid),
      }])
    }, [
      _createVNode(KTPageTitle)
    ], 2)
  ]))
}
}

})