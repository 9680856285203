import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "w-lg-500px p-10"
}
const _hoisted_3 = { class: "text-center mb-10" }
const _hoisted_4 = { class: "text-dark mb-3" }
const _hoisted_5 = { class: "fv-row mb-10" }
const _hoisted_6 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_7 = { class: "fv-plugins-message-container" }
const _hoisted_8 = { class: "fv-help-block" }
const _hoisted_9 = { class: "fv-row mb-10" }
const _hoisted_10 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_11 = { class: "fv-plugins-message-container" }
const _hoisted_12 = { class: "fv-help-block" }
const _hoisted_13 = { class: "fv-row mb-10" }
const _hoisted_14 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_15 = { class: "fv-plugins-message-container" }
const _hoisted_16 = { class: "fv-help-block" }
const _hoisted_17 = { class: "fv-row mb-10" }
const _hoisted_18 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_19 = { class: "row" }
const _hoisted_20 = { class: "col-lg-3 fv-row" }
const _hoisted_21 = ["value"]
const _hoisted_22 = { class: "fv-plugins-message-container" }
const _hoisted_23 = { class: "fv-help-block" }
const _hoisted_24 = { class: "col-lg-9 fv-row" }
const _hoisted_25 = { class: "fv-plugins-message-container" }
const _hoisted_26 = { class: "fv-help-block" }
const _hoisted_27 = { class: "fv-row mb-10" }
const _hoisted_28 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_29 = { class: "fv-plugins-message-container" }
const _hoisted_30 = { class: "fv-help-block" }
const _hoisted_31 = { class: "fv-row mb-10" }
const _hoisted_32 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_33 = { class: "fv-plugins-message-container" }
const _hoisted_34 = { class: "fv-help-block" }
const _hoisted_35 = { class: "fv-row mb-10" }
const _hoisted_36 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_37 = { class: "fv-plugins-message-container" }
const _hoisted_38 = { class: "fv-help-block" }
const _hoisted_39 = { class: "fv-row mb-10" }
const _hoisted_40 = { class: "form-label fw-bold text-dark fs-6 mb-0" }
const _hoisted_41 = { class: "fv-plugins-message-container" }
const _hoisted_42 = { class: "fv-help-block" }
const _hoisted_43 = { class: "fv-row mb-10" }
const _hoisted_44 = { class: "form-label fw-bold text-dark fs-6 mb-0" }
const _hoisted_45 = { class: "fv-plugins-message-container" }
const _hoisted_46 = { class: "fv-help-block" }
const _hoisted_47 = { class: "text-center" }

import { ref, onMounted, inject } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import JwtService from "@/core/services/JwtService";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { WSPusher } from "@/core/plugins/pusher";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import phoneData from "@/core/data/phonesData";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignUp',
  setup(__props) {

const isLoading = ref<boolean>(false);
const store = useStore();
const router = useRouter();

const submitButton = ref<HTMLButtonElement | null>(null);
const wsPusher = inject("wsPusher") as WSPusher;
const phoneDataList = ref(phoneData);

onMounted(() => {
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
});

//Create form validation object
const register = Yup.object().shape({
  first_name: Yup.string().required().label("First Name"),
  last_name: Yup.string().required().label("Last Name"),
  country_code: Yup.string().required().label("Nationality"),
  ccc: Yup.string().required().label("Code"),
  phone: Yup.string().required().label("Phone"),
  currency: Yup.string().required().label("Currency"),
  refer_code: Yup.string().label("Referral Code"),
  email: Yup.string().email().required().label("Email"),
  password: Yup.string().min(4).required().label("Password"),
  password_confirmation: Yup.string()
    .min(4)
    .required()
    .label("Confirm Password"),
});

const computeInttoString = (value: number) => {
  return value.toString();
};

//Form submit function
const onSubmitRegister = async (values) => {
  console.log(values);

  // Clear existing errors
  store.dispatch(Actions.LOGOUT);

  if (submitButton.value) {
    // eslint-disable-next-line
        submitButton.value!.disabled = true;
    // Activate indicator
    submitButton.value.setAttribute("data-kt-indicator", "on");
  }

  // Send register request
  await store.dispatch(Actions.REGISTER, values);
  const [errorName] = Object.keys(store.getters.getErrors);
  const error = store.getters.getErrors[errorName];
  if (!error) {
    wsPusher.setup(JwtService.getToken());
    Swal.fire({
      text: "You have successfully registered!",
      icon: "success",
      buttonsStyling: false,
      confirmButtonText: "Ok, got it!",
      customClass: {
        confirmButton: "btn fw-semobold btn-light-primary",
      },
    }).then(function () {
      // Go to page after successfully register
      router.push({ name: "sign-in" });
    });
  } else {
    Swal.fire({
      text: error,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: "Try again!",
      customClass: {
        confirmButton: "btn fw-semobold btn-light-danger",
      },
    });
  }

  //Deactivate indicator
  submitButton.value?.removeAttribute("data-kt-indicator");
  // eslint-disable-next-line
  submitButton.value!.disabled = false;
};

return (_ctx: any,_cache: any) => {
  return (isLoading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, "Loading"))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createVNode(_unref(Form), {
          class: "form w-100",
          id: "kt_register_signup_form",
          onSubmit: onSubmitRegister,
          "validation-schema": _unref(register)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("signup.signup")), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("signup.first_name")), 1),
              _createVNode(_unref(Field), {
                tabindex: "1",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "first_name",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(ErrorMessage), { name: "first_name" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("signup.last_name")), 1),
              _createVNode(_unref(Field), {
                tabindex: "2",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "last_name",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_unref(ErrorMessage), { name: "last_name" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("signup.country_code")), 1),
              _createVNode(_unref(Field), {
                tabindex: "3",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "country_code",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_unref(ErrorMessage), { name: "country_code" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t("signup.phone")), 1),
              _createElementVNode("div", _hoisted_19, [
                _createElementVNode("div", _hoisted_20, [
                  _createVNode(_unref(Field), {
                    class: "form-control form-control-lg form-control-solid",
                    name: "ccc",
                    as: "select"
                  }, {
                    default: _withCtx(() => [
                      _cache[0] || (_cache[0] = _createElementVNode("option", {
                        value: "",
                        disabled: ""
                      }, "code", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(phoneDataList.value, (item, index) => {
                        return (_openBlock(), _createElementBlock("option", {
                          key: index,
                          value: computeInttoString(item.dialCode)
                        }, " + " + _toDisplayString(item.dialCode) + " " + _toDisplayString(item.name), 9, _hoisted_21))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createElementVNode("div", _hoisted_22, [
                    _createElementVNode("div", _hoisted_23, [
                      _createVNode(_unref(ErrorMessage), { name: "ccc" })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createVNode(_unref(Field), {
                    tabindex: "4",
                    class: "form-control form-control-lg form-control-solid",
                    type: "text",
                    name: "phone",
                    autocomplete: "off"
                  }),
                  _createElementVNode("div", _hoisted_25, [
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_unref(ErrorMessage), { name: "phone" })
                    ])
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("signup.currency")), 1),
              _createVNode(_unref(Field), {
                tabindex: "5",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "currency",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("div", _hoisted_30, [
                  _createVNode(_unref(ErrorMessage), { name: "currency" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_31, [
              _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t("signup.email")), 1),
              _createVNode(_unref(Field), {
                tabindex: "6",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "email",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_33, [
                _createElementVNode("div", _hoisted_34, [
                  _createVNode(_unref(ErrorMessage), { name: "email" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t("signup.referral")), 1),
              _createVNode(_unref(Field), {
                tabindex: "7",
                class: "form-control form-control-lg form-control-solid",
                type: "text",
                name: "refer_code",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("div", _hoisted_38, [
                  _createVNode(_unref(ErrorMessage), { name: "refer_code" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_39, [
              _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t("signup.password")), 1),
              _createVNode(_unref(Field), {
                tabindex: "8",
                class: "form-control form-control-lg form-control-solid",
                type: "password",
                name: "password",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("div", _hoisted_42, [
                  _createVNode(_unref(ErrorMessage), { name: "password" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("label", _hoisted_44, _toDisplayString(_ctx.$t("signup.confirm_password")), 1),
              _createVNode(_unref(Field), {
                tabindex: "9",
                class: "form-control form-control-lg form-control-solid",
                type: "password",
                name: "password_confirmation",
                autocomplete: "off"
              }),
              _createElementVNode("div", _hoisted_45, [
                _createElementVNode("div", _hoisted_46, [
                  _createVNode(_unref(ErrorMessage), { name: "password_confirmation" })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("button", {
                tabindex: "10",
                type: "submit",
                ref_key: "submitButton",
                ref: submitButton,
                id: "kt_sign_in_submit",
                class: "btn btn-lg btn-primary w-100 mb-5"
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("span", { class: "indicator-label" }, " Continue ", -1),
                _createElementVNode("span", { class: "indicator-progress" }, [
                  _createTextVNode(" Please wait... "),
                  _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" })
                ], -1)
              ]), 512),
              _cache[2] || (_cache[2] = _createElementVNode("div", { class: "text-center text-muted text-uppercase fw-bold mb-5" }, "or", -1)),
              _cache[3] || (_cache[3] = _createElementVNode("a", {
                href: "#",
                class: "btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
              }, " Sign In ", -1))
            ])
          ]),
          _: 1
        }, 8, ["validation-schema"])
      ]))
}
}

})