import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "card p-5" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "svg-icon svg-icon-1" }
const _hoisted_5 = { class: "card-body" }
const _hoisted_6 = { class: "py-10 px-lg-17" }
const _hoisted_7 = {
  class: "scroll-y me-n7 pe-7",
  id: "kt_modal_add_customer_scroll",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-max-height": "auto",
  "data-kt-scroll-dependencies": "#kt_modal_add_customer_header",
  "data-kt-scroll-wrappers": "#kt_modal_add_customer_scroll",
  "data-kt-scroll-offset": "300px"
}
const _hoisted_8 = { class: "fv-row mb-7" }
const _hoisted_9 = { class: "fv-row mb-7" }
const _hoisted_10 = { class: "text-right" }
const _hoisted_11 = ["data-kt-indicator"]
const _hoisted_12 = {
  key: 0,
  class: "indicator-label"
}
const _hoisted_13 = { class: "svg-icon svg-icon-3 ms-2 me-0" }
const _hoisted_14 = {
  key: 1,
  class: "indicator-progress"
}

import { onMounted, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'UserCreate',
  setup(__props) {

const formData = ref({
  name: "",
  email: "",
});
onMounted(() => {
  console.log("Create new User");
});

return (_ctx: any,_cache: any) => {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("h3", { class: "card-title fw-bold text-dark" }, "Create new User", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "btn btn-sm btn-icon btn-active-color-primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, [
          _createElementVNode("span", _hoisted_4, [
            _createVNode(_component_inline_svg, { src: "/images/icons/arrows/arr061.svg" })
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_el_form, {
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.submit()), ["prevent"])),
        model: formData.value,
        rules: _ctx.rules,
        ref: "formRef"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[5] || (_cache[5] = _createElementVNode("label", { class: "required fs-6 fw-semobold mb-2" }, "Name", -1)),
                _createVNode(_component_el_form_item, { prop: "name" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: formData.value.name,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((formData.value.name) = $event)),
                      type: "text",
                      placeholder: ""
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_9, [
                _cache[6] || (_cache[6] = _createElementVNode("label", { class: "fs-6 fw-semobold mb-2" }, [
                  _createElementVNode("span", { class: "required" }, "Email"),
                  _createElementVNode("i", {
                    class: "fas fa-exclamation-circle ms-1 fs-7",
                    "data-bs-toggle": "tooltip",
                    title: "Email address must be active"
                  })
                ], -1)),
                _createVNode(_component_el_form_item, { prop: "email" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, {
                      modelValue: formData.value.email,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((formData.value.email) = $event))
                    }, null, 8, ["modelValue"])
                  ]),
                  _: 1
                })
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[9] || (_cache[9] = _createElementVNode("button", {
              type: "reset",
              id: "kt_modal_add_customer_cancel",
              class: "btn btn-light me-3"
            }, " Discard ", -1)),
            _createElementVNode("button", {
              "data-kt-indicator": _ctx.loading ? 'on' : null,
              class: "btn btn-lg btn-primary",
              type: "submit"
            }, [
              (!_ctx.loading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_12, [
                    _cache[7] || (_cache[7] = _createTextVNode(" Submit ")),
                    _createElementVNode("span", _hoisted_13, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr064.svg" })
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("span", _hoisted_14, _cache[8] || (_cache[8] = [
                    _createTextVNode(" Please wait... "),
                    _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1)
                  ])))
                : _createCommentVNode("", true)
            ], 8, _hoisted_11)
          ])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ])
  ]))
}
}

})