import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-stretch flex-shrink-0" }
const _hoisted_2 = { class: "topbar d-flex align-items-stretch flex-shrink-0" }
const _hoisted_3 = {
  class: "d-flex align-items-center me-n3 ms-1 ms-lg-3",
  id: "kt_header_user_menu_toggle"
}
const _hoisted_4 = {
  class: "btn btn-icon btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px symbol symbol-35px symbol-md-40px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-attach": "parent",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "bottom"
}

import { ref } from "vue";
import KTUserMenu from "@/layouts/main-layout/header/partials/UserMenu.vue";
import { useStore } from "vuex";
// import BPWSNotify from "@/layouts/main-layout/notify/WSNotify.vue";


const __default__ = {
  name: "layout-topbar",
};

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const store = useStore();
const user = ref(store.state.AuthModule.user);

return (_ctx: any,_cache: any) => {
  const _component_UserAvatar = _resolveComponent("UserAvatar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_UserAvatar, {
            avatar: user.value.avatar_url,
            name: user.value.name,
            size: "40px"
          }, null, 8, ["avatar", "name"])
        ]),
        _createVNode(KTUserMenu)
      ])
    ])
  ]))
}
}

})