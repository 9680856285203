import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, watch } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusBadge',
  props: {
  status: { type: String, required: true },
},
  setup(__props) {

const props = __props;

const status = ref(props.status);

const badgeClass = ref({
  pedding: "badge-light-primary",
  approved: "badge-light-success",
  rejected: "badge-light-danger",
  cancelled: "badge-light-danger",
  completed: "badge-light-success",
  processing: "badge-light-info",
  pending: "badge-light-primary",
  active: "badge-light-success",
  inactive: "badge-light-danger",
  suspended: "badge-light-danger",
  expired: "badge-light-danger",
  blocked: "badge-light-danger",
  unverified: "badge-light-warning",
  verified: "badge-light-success",
  unpaid: "badge-light-warning",
  paid: "badge-light-success",
  failed: "badge-light-danger",
  device_0: "bg-secondary",
  device_1: "bg-success",
  device_2: "bg-warning",
  unknown: "bg-dark",
  alert: "bg-danger",
});
if (badgeClass.value[props.status] === undefined) {
  status.value = "unknown";
}

watch(
  () => props.status,
  (newStatus) => {
    status.value = newStatus;
  }
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["badge", badgeClass.value[status.value]])
  }, _toDisplayString(_ctx.$t("status." + props.status)), 3))
}
}

})