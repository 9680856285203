import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import moment from "moment";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'TimeShow',
  props: {
    dateIsoString: {},
    format: {}
  },
  setup(__props: any) {

const props = __props;

const getDateAndTimeFromISOString = () => {
  const date = moment(props.dateIsoString);

  if (date.year() === 1970 || date.year() === 1969) {
    return "-";
  }

  // const now = moment().locale(useStore().state.AuthModule.user.language);
  // const threeDaysAgo = now.clone().subtract(5, "days");

  // if (date.isBetween(threeDaysAgo, now)) {
  //   return date.fromNow();
  // }

  // return date.locale(useStore().state.AuthModule.user.language).fromNow();
  if (props.format) {
    return date.format(props.format);
  }
  if (date.year() === moment().year()) {
    return date.format("MM-DD HH:mm:ss");
  }
  return date.format("YYYY-MM-DD HH:mm:ss");
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, _toDisplayString(getDateAndTimeFromISOString()), 1))
}
}

})