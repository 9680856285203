<template>
  <!--begin::Accordion-->
  <div class="accordion" id="kt_accordion_v1">
    <div class="accordion-item">
      <h2 class="accordion-header" id="kt_accordion_1_header_v1">
        <button
          class="accordion-button fs-4 fw-semibold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_accordion_1_body_v1"
          aria-expanded="false"
          aria-controls="kt_accordion_1_body_v1"
        >
          Event Date: 2024-09-29, 23:19:22
        </button>
      </h2>
      <div
        id="kt_accordion_1_body_v1"
        class="accordion-collapse collapse"
        aria-labelledby="kt_accordion_1_header_v1"
        data-bs-parent="#kt_accordion_v1"
      >
        <div class="accordion-body">
          <div>Event Start Time: 2024-09-29, 23:19:22</div>
          <div>Event Duration: 14.5 minutes</div>
          <video controls>
            <source
              :src="'https://storage.cloud.google.com/aiwaysion-test-bucket/graffiti-event-video/2024-09-29_spokane_graffiti.mp4'"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>

    <div class="accordion-item">
      <h2 class="accordion-header" id="kt_accordion_1_header_v2">
        <button
          class="accordion-button fs-4 fw-semibold collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_accordion_1_body_v2"
          aria-expanded="false"
          aria-controls="kt_accordion_1_body_v2"
        >
          Event Date: 2024-09-21, 10:12:28
        </button>
      </h2>
      <div
        id="kt_accordion_1_body_v2"
        class="accordion-collapse collapse"
        aria-labelledby="kt_accordion_1_header_v2"
        data-bs-parent="#kt_accordion_v1"
      >
        <div class="accordion-body">
          <div>Event Start Time: 2024-09-21, 10:12:28</div>
          <div>Event Duration: 14.5 minutes</div>
          <video controls>
            <source
              :src="'https://storage.googleapis.com/aiwaysion-test-bucket/graffiti-event-video/2024-09-21_spokane-graffiti.mp4'"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </div>
  </div>
  <!--end::Accordion-->
</template>

<style scoped>
video {
  width: 100%;
  height: auto;
}
</style>
