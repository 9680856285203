import MenuItem from "@/core/types/MenuItem";

const DocMenu: Array<MenuItem> = [
  {
    pages: [
      {
        heading: "title.Dashboard",
        route: "/dashboard",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["any"],
      },
      {
        heading: "title.AllDevices",
        route: "/alldevices",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["manage"],
      },
      {
        heading: "title.Roadway",
        route: "/devices",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["manage"],
      },
      {
        heading: "title.Intersection",
        route: "/intersection",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["manage"],
      },
      {
        heading: "title.Parking",
        route: "/parking",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["manage"],
      },
      {
        heading: "title.VRU",
        route: "/vru",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["manage"],
      },
      {
        heading: "title.GraffitiDetection",
        route: "/graffiti",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["any"],
      },
      {
        heading: "title.ContactUs",
        route: "/contact-us",
        svgIcon: "/images/icons/art/art002.svg",
        fontIcon: "bi-app-indicator",
        permissions: ["any"],
      },
    ],
  },
  {
    heading: "title.Manage",
    route: "/manage",
    permissions: ["manage"],
    pages: [
      {
        heading: "title.ManageUsers",
        route: "/manage/users",
        svgIcon: "/images/icons//abstract/abs039.svg",
        permissions: ["manage"],
      },
      {
        heading: "title.ManageAccounts",
        route: "/manage/accounts",
        svgIcon: "/images/icons//abstract/abs039.svg",
        permissions: ["manage"],
      },
      {
        heading: "title.ManageDevices",
        route: "/manage/devices",
        svgIcon: "/images/icons//abstract/abs039.svg",
        permissions: ["manage"],
      },
    ],
  },
];

// heading: "title.manage",
//         route: "/manage",
//         permissions: ["ib.view"],
//         pages2: [
//           {
//             heading: "title.IB",
//             route: "/ib",
//             svgIcon: "/images/icons//abstract/abs039.svg",
//             permissions: ["ib.view"],
//           },
//         ],
const SystemMenu: Array<MenuItem> = [];
// function initMainMenu() {
//   const menuItems = DocMenuConfig.concat(moduleMenu ?? {}, SystemMenuConfig);
//   return menuItems;
// }
// const menuItems = DocMenuConfig.concat(moduleMenu ?? {}, SystemMenuConfig);
// export default initMainMenu;
export { DocMenu, SystemMenu };
