import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-lg-500px p-10" }
const _hoisted_2 = { class: "text-center mb-10" }
const _hoisted_3 = { class: "text-dark mb-3" }
const _hoisted_4 = { class: "fv-row mb-10" }
const _hoisted_5 = { class: "form-label fs-6 fw-bold text-dark" }
const _hoisted_6 = { class: "fv-plugins-message-container" }
const _hoisted_7 = { class: "fv-help-block" }
const _hoisted_8 = { class: "text-center" }
const _hoisted_9 = {
  tabindex: "3",
  type: "submit",
  ref: "submitButton",
  id: "kt_sign_in_submit",
  class: "btn btn-lg btn-primary w-100 mb-5"
}
const _hoisted_10 = { class: "indicator-label" }
const _hoisted_11 = { class: "indicator-progress" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Form, {
      class: "form w-100",
      id: "kt_login_signin_form",
      onSubmit: _ctx.onSubmitLogin,
      "validation-schema": _ctx.login
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("auth.Two-factor authentication")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("common.code")), 1),
          _createVNode(_component_Field, {
            tabindex: "1",
            class: "form-control form-control-lg form-control-solid",
            type: "text",
            name: "code",
            autocomplete: "off"
          }),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_ErrorMessage, { name: "code" })
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("button", _hoisted_9, [
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.$t("common.Continue")), 1),
            _createElementVNode("span", _hoisted_11, [
              _createTextVNode(_toDisplayString(_ctx.$t("common.PleaseWait")) + " ", 1),
              _cache[0] || (_cache[0] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
            ])
          ], 512)
        ])
      ]),
      _: 1
    }, 8, ["onSubmit", "validation-schema"])
  ]))
}