import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_2 = {
  id: "kt_wrapper",
  class: "wrapper d-flex flex-column flex-row-fluid"
}
const _hoisted_3 = { class: "content flex-row-fluid" }

import { onMounted, watch, nextTick, ref } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTAside from "@/layouts/main-layout/aside/Aside.vue";
import KTHeader from "@/layouts/main-layout/header/Header.vue";
import KTFooter from "@/layouts/main-layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import KTToolbar from "@/layouts/main-layout/toolbar/Toolbar.vue";
import KTLoader from "@/components/Loader.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components";
import { reinitializeComponents } from "@/core/plugins/plugins";
import { removeModalBackdrop } from "@/core/helpers/dom";
import {
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";


export default /*@__PURE__*/_defineComponent({
  __name: 'MainLayout',
  setup(__props) {

const store = useStore();
const route = useRoute();
const router = useRouter();
const toolBarAction = ref(null);

store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

onMounted(() => {
  //check if current user is authenticated
  if (!store.getters.isUserAuthenticated) {
    router.push({ name: "sign-in" });
  }

  nextTick(() => {
    reinitializeComponents();
  });
  // initialize html element classes
  HtmlClass.init();
  // Simulate the delay page loading
  setTimeout(() => {
    // Remove page loader after some time
    store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
  }, 500);
});

watch(
  () => route.path,
  () => {
    MenuComponent.hideDropdowns(undefined);

    // check if current user is authenticated
    if (!store.getters.isUserAuthenticated) {
      router.push({ name: "sign-in" });
    }

    removeModalBackdrop();
    nextTick(() => {
      reinitializeComponents();
    });
  }
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(loaderEnabled))
      ? (_openBlock(), _createBlock(KTLoader, {
          key: 0,
          logo: _unref(loaderLogo)
        }, null, 8, ["logo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(KTHeader),
        _createVNode(KTToolbar, {
          ref_key: "toolBarAction",
          ref: toolBarAction
        }, null, 512),
        _createElementVNode("div", {
          id: "kt_content",
          class: _normalizeClass(["d-flex flex-column-fluid align-items-start", {
          'container-fluid': _unref(contentWidthFluid),
          'container-xxl': !_unref(contentWidthFluid),
        }])
        }, [
          (_unref(asideEnabled))
            ? (_openBlock(), _createBlock(KTAside, {
                key: 0,
                lightLogo: _unref(themeLightLogo),
                darkLogo: _unref(themeDarkLogo)
              }, null, 8, ["lightLogo", "darkLogo"]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_router_view)
          ])
        ], 2),
        _createVNode(KTFooter)
      ])
    ])
  ], 64))
}
}

})