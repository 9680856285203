import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_2 = { class: "flex-grow-1" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "d-flex align-items-center mb-2" }
const _hoisted_6 = { class: "text-gray-800 fs-2 fw-bold me-1" }
const _hoisted_7 = {
  key: 0,
  class: "btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_8 = {
  key: 1,
  class: "btn btn-sm btn-light-danger fw-bold ms-2 fs-8 py-1 px-3"
}
const _hoisted_9 = { class: "d-flex fw-semobold fs-6 mb-2 pe-2 vstack" }
const _hoisted_10 = { class: "d-flex align-items-center me-5 mb-2" }
const _hoisted_11 = { class: "d-flex align-items-center me-5 mb-2" }




export default /*@__PURE__*/_defineComponent({
  __name: 'DeviceInfo',
  props: {
  info: {
    type: Object,
    required: true,
  },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "me-7" }, [
      _createElementVNode("div", { class: "symbol symbol-100px symbol-lg-200px symbol-fixed position-cneter" }, [
        _createElementVNode("img", {
          class: "",
          src: "/images/bg/MUST_Device.PNG",
          alt: "Device image"
        })
      ])
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createTextVNode(_toDisplayString(props.info.name) + " ", 1),
              (props.info.status == '1')
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, " Online "))
                : (_openBlock(), _createElementBlock("div", _hoisted_8, " Offline "))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, " Location: " + _toDisplayString(props.info.location), 1),
            _createElementVNode("div", _hoisted_11, " Installed at: " + _toDisplayString(props.info.created_at), 1)
          ])
        ])
      ])
    ])
  ]))
}
}

})