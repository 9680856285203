import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-center flex-column-fluid" }
const _hoisted_2 = { class: "d-flex flex-column flex-center text-center p-10" }
const _hoisted_3 = { class: "card card-flush w-lg-650px py-5" }
const _hoisted_4 = { class: "card-body py-15 py-lg-20" }
const _hoisted_5 = { class: "fw-bolder fs-2hx text-gray-900 mb-4" }
const _hoisted_6 = { class: "fw-semibold fs-6 text-gray-500 mb-7" }
const _hoisted_7 = { class: "mb-0" }

import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export default /*@__PURE__*/_defineComponent({
  __name: 'EmailVerification',
  setup(__props) {

const router = useRouter();
const store = useStore();
const virifyEmail = () => {
  console.log(router.currentRoute.value.query);
  ApiService.post("auth/verify-email", router.currentRoute.value.query)
    .then(({ data }) => {
      console.log(data);
      router.push({ name: "Login" });
    })
    .catch(({ response }) => {
      console.log(response);
    });
};
onMounted(() => {
  console.log("mounted");
  virifyEmail();
  store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
  store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
});

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.$t("tips.EmailVerification")), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("tips.EmailVerificationText")), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "mb-3" }, [
            _createElementVNode("img", {
              src: "assets/media/illustrations/verification.svg",
              alt: "verification",
              class: "mw-100 mh-250px"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_router_link, {
              to: "/",
              class: "btn btn-sm btn-primary"
            }, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("Return Home")
              ])),
              _: 1
            })
          ])
        ])
      ])
    ])
  ]))
}
}

})